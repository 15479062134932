import React, { useState} from "react";
import {connect} from "react-redux";
import Loader from "../../common/loader";
function AntenatalCarePackageForm(props) {
    const { formData, setFormData, onEdit, onSubmit, IsFormLoading} = props;
    const [IsLoading, setIsLoading] = useState(false)


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Package Name</label>
                                <input
                                    id="packageName"
                                    name="packageName"
                                    className="form-control"
                                    value={formData.packageName}
                                    onChange={onEdit}
                                    readOnly
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Package Amount</label>
                                <input
                                    id="amount"
                                    name="amount"
                                    className="form-control"
                                    value={formData.amount}
                                    onChange={onEdit}
                                    readOnly
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    name="startDate"
                                    className="form-control"
                                    id="startDate"
                                    value={formData.startDate}
                                    onChange={onEdit}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">End Date</label>
                                <input
                                    type="date"
                                    name="expiryDate"
                                    className="form-control"
                                    id="expiryDate"
                                    value={formData.expiryDate}
                                    onChange={onEdit}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Visit Date</label>
                                <input
                                    type="date"
                                    name="visitDate"
                                    className="form-control"
                                    id="visitDate"
                                    value={formData.visitDate}
                                    onChange={onEdit}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Remark</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="remark"
                                    className="form-control"
                                    id="remark"
                                    value={formData.remark}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(AntenatalCarePackageForm);