import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import ServiceTypeForm from "./service-type-form";
import ServiceForm from "./service-form";

function ServiceSettings(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [serviceTypeList, setServiceTypeList] = useState([])
    const [serviceList, setServiceList] = useState([])
    const header = ["S/N", "service Name",  "service Description", "Updated By", "Updated Date", "Action"];
    const header3 = ["S/N", "service Item Name","Service Type Name",  "service Amount", "Status",  "Updated By", "Updated Date",  "Action"];
    const [formData, setFormData] = useState({
        serviceID: "",
        serviceName: "",
        serviceDescription: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })
    const [formData2, setFormData2] = useState({
        serviceDataID: "",
        serviceItemName: "",
        serviceID: "",
        serviceAmount: "",
        serviceDescription: "",
        serviceStatus: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}doctor/schedule/services/data`)
            .then((result) => {
                setServiceTypeList(result.data.serviceTypes);
                setServiceList(result.data.services);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return serviceTypeList.length > 0 && serviceTypeList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.serviceName}</td>
                        <td>{item.serviceDescription}</td>
                        <td>{item.updatedBy}</td>
                        <td>{formatDateAndTime(item.updatedOn, 'date')}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       serviceID: item.serviceID,
                                       serviceName: item.serviceName,
                                       serviceDescription: item.serviceDescription,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable3 = () => {
        try {
            return serviceList.length > 0 &&  serviceList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.serviceItemName}</td>
                        <td>{item.serviceName}</td>
                        <td>{currencyConverter(item.serviceAmount)}</td>
                        <td className="text-capitalize">{item.serviceStatus}</td>
                        <td>{item.updatedBy}</td>
                        <td>{formatDateAndTime(item.updatedOn, 'date')}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal2"
                               onClick={() => {
                                   setFormData2({
                                       ...formData2,
                                       serviceDataID: item.serviceDataID,
                                       serviceItemName: item.serviceItemName,
                                       serviceID: item.serviceID,
                                       serviceAmount: item.serviceAmount,
                                       serviceDescription: item.serviceDescription,
                                       serviceStatus: item.serviceStatus,
                                       Visibility: item.Visibility,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onEdit2 = (e) => {
        setFormData2({
            ...formData2,
            [e.target.id]: e.target.value,
        });
    }


    const onSubmit = async () => {
        if (formData.serviceName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter service name", "error");
            return false;
        }

        if (formData.serviceID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}doctor/schedule/services-type/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Service Type Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            serviceID: "",
                            serviceName: "",
                            serviceDescription: "",
                        });

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}doctor/schedule/services-type/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Service Type Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            serviceID: "",
                            serviceName: "",
                            serviceDescription: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit2 = async () => {
        if (formData2.serviceID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select service type", "error");
            return false;
        }

        if (formData2.serviceItemName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter service name", "error");
            return false;
        }

        if (formData2.serviceAmount.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter service amount", "error");
            return false;
        }

        if (formData2.serviceStatus.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select service status", "error");
            return false;
        }

        if (formData2.serviceDataID === "") {
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}doctor/schedule/service/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Service Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData2({
                            ...formData2,
                            serviceDataID: "",
                            serviceItemName: "",
                            serviceID: "",
                            serviceAmount: "",
                            serviceMinAmount: "",
                            serviceDescription: "",
                            serviceStatus: "",
                        });

                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}doctor/schedule/service/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Service Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData2({
                            ...formData2,
                            serviceDataID: "",
                            serviceItemName: "",
                            serviceID: "",
                            serviceAmount: "",
                            serviceMinAmount: "",
                            serviceDescription: "",
                            serviceStatus: "",
                        });
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Service Settings"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <ul className="nav nav-tabs customtab" role="tablist">
                                        <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#home2" role="tab"><span className="hidden-sm-up"><i className="fa fa-cubes text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Services</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#profile2" role="tab"><span className="hidden-sm-up"><i className="fa fa-cubes-stacked text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Service Type</span></a> </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="home2" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Services</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal2" onClick={()=>{
                                                            setFormData({
                                                                ...formData,
                                                                serviceDataID: "",
                                                                serviceItemName: "",
                                                                serviceID: "",
                                                                serviceAmount: "",
                                                                serviceMinAmount: "",
                                                                serviceDescription: "",
                                                                serviceStatus: "",
                                                            });
                                                        }}>
                                                            <i className="fa fa-plus"></i> Add Services
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable tableID="service" header={header3} body={showTable3()} title="Service List" />
                                        </div>

                                        <div className="tab-pane  p-10" id="profile2" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Service Type</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal"
                                                                data-target="#responsive-modal" onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                serviceID: "",
                                                                serviceName: "",
                                                                serviceDescription: "",
                                                            });
                                                        }}>
                                                            <i className="fa fa-plus"></i> Add Service Type
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable  tableID="service-type"  header={header} body={showTable()} title="Service Type List" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ServiceTypeForm
                    id="responsive-modal"
                    title="Manage Service Type"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                    modalID="modal-report"
                />
                <ServiceForm
                    id="responsive-modal2"
                    close="closeModal"
                    title="Manage Service"
                    formData={formData2}
                    serviceTypeList={serviceTypeList}
                    onEdit={onEdit2}
                    onSubmit={onSubmit2}
                    IsFormLoading={IsFormLoading2}
                    setFormData={setFormData2}
                    modalID="modal-report2"
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(ServiceSettings);

