import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import Accordion from  '../../../components/common/accordion/accordion';

const PatientPrescriptionAssessment = ({patientSerial, serviceID, serviceName, handleRemount, loginData, setDoctorFormData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [prescription, setPrescription] = useState([])
    const [prescriptionList, setPrescriptionList] = useState([])
    const [assessmentList, setAssessmentList] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        const url = !serviceID ? `${serverLink}pharmacy/prescription/request/${patientSerial}` : `${serverLink}pharmacy/prescription/assessment/${serviceName.toLowerCase()}/${patientSerial}/${serviceID}`
        await axios.get(url, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setPrescription(result.data.drug);
                    setPrescriptionList(result.data.prescription);
                    setAssessmentList(result.data.assessment);
                }else {
                    setPrescription([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Prescription Assessment</h3>
                    {
                        prescription.length > 0 ?  prescription.map((element, index) => {
                            return (
                                <Accordion
                                    index={index}
                                    header={`${formatDateAndTime(element.submittedDate, 'date_and_time')} => Prescribed By: ${element.doctorName}`}
                                    content={
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Drug Name</th>
                                                    <th>Dose</th>
                                                    <th>Frequency</th>
                                                    <th>Prescription</th>
                                                    <th>Assessment</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    prescriptionList.filter(e=>e.drugPrescriptionID === element.drugPrescriptionID).map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{key +1}</td>
                                                                <td>{item.drugName}</td>
                                                                <td>{item.drugDose}</td>
                                                                <td>{item.doseFrequency}</td>
                                                                <td>{item.prescription}</td>
                                                                <td>
                                                                    {
                                                                        assessmentList.filter(x=>x.prescriptionID === item.prescriptionID).length > 0 ?
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Time Given</th>
                                                                                        <th>Dose Given</th>
                                                                                        <th>Administered By</th>
                                                                                        <th>Date Administered</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        assessmentList.filter(x=>x.prescriptionID === item.prescriptionID).map((x, i)=>{
                                                                                            return (
                                                                                                <tr key={i}>
                                                                                                    <td>{x.timeGiven}</td>
                                                                                                    <td>{x.doseGiven}</td>
                                                                                                    <td>{x.administeredByName}</td>
                                                                                                    <td>{formatDateAndTime(x.administeredDate, 'date_and_time')}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <div className="alert alert-info text-center"><h4>No Assessment Found</h4></div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                />
                            )
                        }) :  <div className="alert alert-info text-center p-3"><h3>No Record Found!</h3></div>
                    }

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientPrescriptionAssessment);

