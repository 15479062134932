import React, {useEffect, useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function CaptureVitals(props) {
    const { patientSerial, patientName, walletAmount, vitalFormData, serviceID, serviceName, setVitalFormData,handleRemount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [appointmentList, setAppointmentList] = useState([])

    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        vitalID: '',
        serviceID: serviceID,
        serviceType: serviceName,
        pulse: '',
        respiration: '',
        bloodPressure: '',
        bloodPressure_unit: '',
        temperature: '',
        temperature_unit: 'C',
        weight: '',
        weight_unit: '',
        height: '',
        height_unit: 'KG',
        bmi: '',
        bmi_unit: 'M',
        bsa: '',
        spo2: '',
        dateTaken: '',
        timeTaken: '',
        remark: '',
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    useEffect(() => {
        if (Object.keys(vitalFormData).length !== 0) {
            setFormData({
                ...formData,
                ...vitalFormData
            });
        }else{
            setFormData(Initial)
        }
      //  getData();
    }, [vitalFormData, handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/appointment/check-in/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setAppointmentList(result.data.filter(e=>{
                        let currentDate = new Date();
                        let otherDate = new Date(e.appointmentDate);
                        return  currentDate.toDateString() === otherDate.toDateString()
                    }));
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const onCaptureVitals = async () => {
        if (formData.serviceID.toString().trim() === ''){
            toast.error('Please select checked in appointment')
            return false
        }
        if (formData.pulse.toString().trim() === '' && formData.bloodPressure.toString().trim() === ''
            && formData.temperature.toString().trim() === '' && formData.height.toString().trim() === ''
            && formData.weight.toString().trim() === '' && formData.bmi.toString().trim() === ''
            && formData.bsa.toString().trim() === '' && formData.spo2.toString().trim() === ''
            && formData.respiration.toString().trim() === '' && formData.dateTaken.toString().trim() === ''
            && formData.timeTaken.toString().trim() === ''
        ){
            toast.error('You have to capture at least one vital before submitting!')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        if (formData.vitalID === ''){
            await axios.post(`${serverLink}patient/vital/add`, formData, token).then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("Vital Added Successfully");
                    setIsFormLoading(false);
                    props.setRebuildVitals(generate_token(5));
                    document.getElementById("closeVital").click();
                    setVitalFormData({})
                    resetFormData()
                } else {
                    setIsFormLoading(false);
                    toast.error("Something went wrong adding vitals. Please try again!");
                }
            }) .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        }else{
            await axios.patch(`${serverLink}patient/vital/update`, formData, token).then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("Vital Updated Successfully");
                    setIsFormLoading(false);
                    props.setRebuildVitals(generate_token(5));
                    document.getElementById("closeVital").click();
                    setVitalFormData({})
                    resetFormData()
                } else {
                    setIsFormLoading(false);
                    toast.error("Something went wrong updating vitals. Please try again!");
                }
            }) .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        }

    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            {/*<div className="mb-3 form-group col-md-6">*/}
                            {/*    <label className="form-label">Checked in Appointment</label>*/}
                            {/*    <select*/}
                            {/*        id="appointmentID"*/}
                            {/*        name="appointmentID"*/}
                            {/*        className="form-control"*/}
                            {/*        value={formData.appointmentID}*/}
                            {/*        onChange={onEdit}*/}
                            {/*    >*/}
                            {/*        <option value="">Select the Checked in Appointment</option>*/}
                            {/*        {*/}
                            {/*            appointmentList.length > 0 && appointmentList.map((item, index) => {*/}
                            {/*                return (*/}
                            {/*                    <option key={index} value={item.appointmentID}>{item.departmentName} => {item.doctorName} => {item.appointmentTime}</option>*/}
                            {/*                )*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Pulse</label>
                                <input
                                    type="text"
                                    id="pulse"
                                    name="pulse"
                                    className="form-control"
                                    value={formData.pulse}
                                    onChange={onEdit}
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Blood Pressure</label>
                                <input
                                    type="text"
                                    name="bloodPressure"
                                    className="form-control"
                                    id="bloodPressure"
                                    value={formData.bloodPressure}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label"> Temperature</label>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        aria-label="Text input with dropdown button"
                                        id="temperature"
                                        name="temperature"
                                        value={formData.temperature}
                                        onChange={onEdit}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            id="temperature_unit"
                                            name="temperature_unit"
                                            className="form-control"
                                            value={formData.temperature_unit}
                                            onChange={onEdit}
                                        >
                                            <option value="C">Celsius</option>
                                            <option value="F">Fahrenheit</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Height</label>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="height"
                                        name="height"
                                        value={formData.height}
                                        onChange={onEdit}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            id="height_unit"
                                            name="height_unit"
                                            className="form-control"
                                            value={formData.height_unit}
                                            onChange={onEdit}
                                        >
                                            <option value="M">Meter</option>
                                            <option value="CM">Centimeter</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Weight</label>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="weight"
                                        name="weight"
                                        value={formData.weight}
                                        onChange={onEdit}
                                    />
                                    <div className="input-group-append">
                                        <select
                                            id="weight_unit"
                                            name="weight_unit"
                                            className="form-control"
                                            value={formData.weight_unit}
                                            onChange={onEdit}
                                        >
                                            <option value="KG">Kilogram</option>
                                            <option value="LB">Pound</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">BMI</label>
                                <input
                                    type="number"
                                    name="bmi"
                                    className="form-control"
                                    id="bmi"
                                    value={formData.bmi}
                                    onChange={onEdit}
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">BSA</label>
                                <input
                                    type="number"
                                    name="bsa"
                                    className="form-control"
                                    id="bsa"
                                    value={formData.bsa}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">SPO2</label>
                                <input
                                    type="number"
                                    name="spo2"
                                    className="form-control"
                                    id="spo2"
                                    value={formData.spo2}
                                    placeholder="SPO2 (In Percentage)"
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Respiration</label>
                                <input
                                    type="number"
                                    name="respiration"
                                    className="form-control"
                                    id="respiration"
                                    value={formData.respiration}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Vital Taken Date and Time</label>
                                <div className="input-group">
                                    <input
                                        type="date"
                                        required
                                        className="form-control"
                                        id="dateTaken"
                                        name="dateTaken"
                                        value={formData.dateTaken}
                                        onChange={onEdit}
                                    />
                                    <div className="input-group-append">
                                        <input
                                            type="time"
                                            required
                                            className="form-control"
                                            id="timeTaken"
                                            name="timeTaken"
                                            value={formData.timeTaken}
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Remark</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="remark"
                                    className="form-control"
                                    id="remark"
                                    value={formData.remark}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onCaptureVitals}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(CaptureVitals);