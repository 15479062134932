import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import PatientPayments from "../../patient/utils/patient-payments";
import PatientServices from "../../patient/utils/patient-services";
import Allergy from "../../patient/components/allergy";
import RequestService from "../../patient/components/service-request";
import {Link, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {PatientInfoCard} from "../../patient/utils/patient-info-card";
import Pregnancy from "./components/add-pregnancy";
import ObstetricHistory from "./components/obstetric-history";

function AntenatalDashboard(props) {
    const token = props.loginData[0]?.token;
    const {slug} = useParams();
    let antenatalID = '';
    let url = window.location.href;
    let split = url.split('/');
    if (split.length > 0) {
        let lastIndex = split[split.length -2]
        antenatalID = lastIndex
    }
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(props.patientDetails)
    const [rebuildPregnancy, setRebuildPregnancy] = useState(null);
    const [rebuildObstetric, setRebuildObstetric] = useState(null);
    const [rebuildVisit, setRebuildVisit] = useState(null);
    const [rebuildExam, setRebuildExam] = useState(null);
    const [rebuildOutcome, setRebuildOutcome] = useState(null);
    const [rebuildDelivery, setRebuildDelivery] = useState(null);

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}antenatal/details/${atob(antenatalID)}/${atob(slug)}`, token)
            .then(async (result) => {
                const patient = result.data.patient;
                const item = {
                    walletAmount: patient[0].walletAmount,
                    passport: patient[0].passport,
                    firstName: patient[0].firstName,
                    middleName: patient[0].middleName,
                    surname: patient[0].surname,
                    title: patient[0].title,
                    emailAddress: patient[0].emailAddress,
                    phoneNumber: patient[0].phoneNumber,
                    altPhoneNumber: patient[0].altPhoneNumber,
                    dateOfBirth: patient[0].dateOfBirth,
                    bloodGroup: patient[0].bloodGroup,
                    gender: patient[0].gender,
                    status: patient[0].status,
                    maritalStatus: patient[0].maritalStatus,
                    nextOfKinName: patient[0].nextOfKinName,
                    nextOfKinPhone: patient[0].nextOfKinPhone,
                    nextOfKinRelationship: patient[0].nextOfKinRelationship,
                    residentialAddress: patient[0].residentialAddress,
                    lastVisit: patient[0].lastVisit,
                    ...result.data.antenatal[0]
                }
                if (result.data.antenatal.length > 0){
                    setPatientDetails(item)
                }else{
                    navigate('/')
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Antenatal Care Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Antenatal Care Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-pregnancy-tab" data-toggle="pill"
                                           href="#pregnancy" role="tab" aria-controls="pills-pregnancy"
                                           aria-selected="false">Pregnancy Record</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-obstetric-tab" data-toggle="pill"
                                           href="#obstetric"
                                           role="tab" aria-controls="pills-obstetric" aria-selected="false">Obstetric History</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-visit-tab" data-toggle="pill"
                                           href="#visit"
                                           role="tab" aria-controls="pills-visit" aria-selected="false">Risk Factor and Service (Visits)</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-examination-tab" data-toggle="pill"
                                           href="#examination"
                                           role="tab" aria-controls="pills-examination" aria-selected="false">General Examination</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-outcome-tab" data-toggle="pill"
                                           href="#outcome"
                                           role="tab" aria-controls="pills-outcome" aria-selected="false">Pregnancy Outcome</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-delivery-tab" data-toggle="pill"
                                           href="#delivery"
                                           role="tab" aria-controls="pills-delivery" aria-selected="false">Delivery Care</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCard patientDetails={patientDetails}/>
                                                <div className="col-md-8">
                                                    <div className="col-md-12 bg-white mb-3">
                                                        <hr style={{margin: '0px', padding: '0px'}}/>
                                                        <div className="card-body">
                                                            <h4><b>Antenatal Registration Details</b></h4>
                                                            <hr/>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <span
                                                                        className="text-muted p-t-30 db">Package Name</span>
                                                                    <h5>Antenatal Regular Package</h5>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="text-muted p-t-30 db">Amount</span>
                                                                    <h5>{currencyConverter(patientDetails?.amount)}</h5>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span className="text-muted p-t-30 db">Status</span>
                                                                    <h5 className={patientDetails.status === 'Active' ? 'text-success text-capitalize font-weight-bold' : 'text-danger  text-capitalize  font-weight-bold'}>{patientDetails?.status}</h5>
                                                                </div>
                                                                <div className="col-md-4 mt-3">
                                                                    <span
                                                                        className="text-muted p-t-30 db">Start Date</span>
                                                                    <h5>{formatDateAndTime(patientDetails?.startDate, 'date')}</h5>
                                                                </div>
                                                                <div className="col-md-4 mt-3">
                                                                    <span
                                                                        className="text-muted p-t-30 db">Expiry Date</span>
                                                                    <h5>{formatDateAndTime(patientDetails?.expiryDate, 'date')}</h5>
                                                                </div>
                                                                <div className="col-md-4 mt-3">
                                                                    <span className="text-muted p-t-30 db">First Visit Date</span>
                                                                    <h5>{formatDateAndTime(patientDetails?.expiryDate, 'date')}</h5>
                                                                </div>
                                                                <div className="col-md-4 mt-3">
                                                                    <span className="text-muted p-t-30 db">Remark</span>
                                                                    <h5>{patientDetails?.remark}</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <MenuCard title="Add Pregnancy" icon="fa-briefcase-users"
                                                                  size="col-md-3" color="orange" modal="pregnancy-modal"/>
                                                        <MenuCard title="Obstetric History" icon="fa-" size="col-md-3"
                                                                  color="danger" modal="service-modal"/>
                                                        <MenuCard title="Risk Factor and Service (Visits)" icon="fa-exchange" size="col-md-3"
                                                                  color="info" modal="service-modal"/>
                                                        <MenuCard title="General Examination" icon="fa-" size="col-md-3"
                                                                  color="primary" modal="service-modal"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="pills-payments-tab">
                                        <div className="card-body">
                                            <PatientPayments patientSerial={patientDetails.patientSerial} handleRemount={rebuildPregnancy}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="services" role="tabpanel" aria-labelledby="pills-services-tab">
                                        <div className="card-body">
                                            <PatientServices patientSerial={patientDetails.patientSerial} handleRemount={rebuildObstetric}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pregnancy
                    id="pregnancy-modal"
                    title="Add Pregnancy"
                    close="closePregnacy"
                    antenatalID={antenatalID}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={0}
                    setRebuildPregnancy={setRebuildPregnancy}
                />
                <ObstetricHistory
                    id="obstetric-modal"
                    title="Add Obstetric History"
                    close="closeObstetric"
                    antenatalID={antenatalID}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={0}
                    setRebuildObstetric={setRebuildObstetric}
                />


            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(AntenatalDashboard);

