import React from "react";

export default function ServiceTypeForm(props) {
    return <>
        <div id={`${
            typeof props.close !== "undefined" ? props.close : "responsive-modal"
        }`} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Service Name</label>
                                <input
                                    type="text"
                                    name="serviceName"
                                    className="form-control"
                                    id="serviceName"
                                    value={props.formData.serviceName}
                                    onChange={props.onEdit}
                                    placeholder="Service Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Service Description</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="serviceDescription"
                                    className="form-control"
                                    id="serviceDescription"
                                    value={props.formData.serviceDescription}
                                    onChange={props.onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}