import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {BiSolidVial} from "react-icons/bi";
import {FaFlaskVial} from "react-icons/fa6";
const SpecimenItem = (props) => {
    const SIUnitList = ["FI", "FL", "g/l", "IU/l", "IU/ml", "mg/day", "mg/l", "mg/dl", "mg/l", "ML", "ML/Unit", "mIU/l", "mIU/ml", "MM/HR", "mm³", "mmol/l",
        "nmol/l", "ng/ml", "pmol/l", "pg", "pg/ml", "Seconds", "X10⁹/L", "X10¹²/L", "U/l", "Ug/l", "Unit", "%", "μmol/l", "μg/l", "Other" ];

    const [itemFormData, setItemFormData] = useState([])
    const [itemFormData2, setItemFormData2] = useState([])
    const [refIntervalArray1, setRefIntervalArray1] = useState([])
    const [refIntervalArray2, setRefIntervalArray2] = useState([])
    const [refFormData, setRefItemFormData] = useState([])
    const [refFormData2, setRefItemFormData2] = useState([])
    const [noOfItems, setNoOfItems] = useState([{id: 1, name: 'Item'}])

    const onItemEdit = (e, index, specimen_id) => {
        const targetId = e.target.id;
        const value = e.target.value;
        setRefIntervalArray(targetId, value, index);


        setItemFormData((prevState) => {
            const updateFormData = [...prevState];
            updateFormData[index] = {
                ...updateFormData[index],
                [e.target.id]: e.target.value,
            };
            return updateFormData;
        });
    };

    useEffect(() => {
        props.setItemFormData(itemFormData)
    }, [JSON.stringify(itemFormData)]);

    // const onItemEdit = (e, index, specimen_id) => {
    //     const targetId = e.target.id;
    //     const value = e.target.value;
    //     setRefIntervalArray(targetId, value, index);
    //
    //     setItemFormData((prevState) => {
    //         const updatedFormData = [...prevState];
    //         // Find the item based on the specimen_entry_id and index
    //         const otherSpecimenItems = updatedFormData.filter(e => e.specimen_entry_id !== specimen_id);
    //         const itemIndex = updatedFormData.findIndex(e => e.specimen_entry_id === specimen_id);
    //
    //         // If the item is found, update it
    //         if (itemIndex !== -1) {
    //             updatedFormData[itemIndex] = {
    //                 ...updatedFormData[itemIndex],
    //                 [e.target.id]: e.target.value,
    //             };
    //         }
    //         console.log(updatedFormData)
    //         console.log(otherSpecimenItems)
    //         return [...updatedFormData, ...otherSpecimenItems];
    //     });
    //     props.setItemFormData(itemFormData)
    // };

    const onRefEdit = (e, index, key) => {
        setRefItemFormData((prevState) => {
            const updateFormData = [...prevState.filter(element=>element.key === key)];
            updateFormData[index] = {
                ...updateFormData[index],
                [e.target.id]: e.target.value,
            };
            return updateFormData;
        });
    };

    const onRefEdit2 = (e, index, key) => {
        setRefItemFormData2((prevState) => {
            const updateFormData = [...prevState.filter(element=>element.key === key)];
            updateFormData[index] = {
                ...updateFormData[index],
                [e.target.id]: e.target.value,
            };
            return updateFormData;
        });
    };

    const onRefMainTitleEdit = (e, key) => {
        setRefItemFormData((prevState) => {
            return prevState.filter(element=>element.key === key).map((item) => {
                return {
                    ...item,
                    main_ref_title: e.target.value,
                };
            });
        });
    };

    const onRefMainTitleEdit2 = (e, key) => {
        setRefItemFormData2((prevState) => {
            return prevState.filter(element=>element.key === key).map((item) => {
                return {
                    ...item,
                    main_ref_title: e.target.value,
                };
            });
        });
    };


    function setRefIntervalArray(targetId, value, key) {
        let intervalArray = [];
        let ref_interval_type = '';

        switch (value) {
            case 'Single':
                intervalArray.push(1);
                ref_interval_type = value;
                break;
            case 'Two':
                intervalArray.push(1, 2);
                ref_interval_type = value;
                break;
            case 'Three':
                intervalArray.push(1, 2, 3);
                ref_interval_type = value;
                break;
            case 'Four':
                intervalArray.push(1, 2, 3, 4);
                ref_interval_type = value;
                break;
            default:
                intervalArray = [];
                ref_interval_type = '';
        }

        if (targetId === 'ref_interval_one') {
            const newFormData = intervalArray.map((item, int) => ({
                id: int+1,
                key: key,
                interval_type: ref_interval_type,
                main_ref_title: "",
                ref_interval_name: "",
                minimum_interval: "",
                maximum_interval: "",
            }));
            // setRefItemFormData(newFormData);
            // setRefIntervalArray1(intervalArray);
            setRefItemFormData(prevArray => [...prevArray.filter(item => item.key !== key), ...newFormData]);
            setRefIntervalArray1(prevArray => [...prevArray.filter(item => item.key !== key), ...intervalArray]);
        } else if (targetId === 'ref_interval_two') {
            const newFormData = intervalArray.map((item, int) => ({
                id: int+1,
                key: key,
                interval_type: ref_interval_type,
                main_ref_title: "",
                ref_interval_name: "",
                minimum_interval: "",
                maximum_interval: "",
            }));
            // setRefItemFormData2(newFormData);
            // setRefIntervalArray2(intervalArray);
            setRefItemFormData2(prevArray => [...prevArray.filter(item => item.key !== key), ...newFormData]);
            setRefIntervalArray2(prevArray => [...prevArray.filter(item => item.key !== key), ...intervalArray]);
        }
    }

    const onAddItem = () => {
        setNoOfItems((prevState) => [...prevState, { id: noOfItems.length + 1, name: `Item` }]);
        const initialValue = {
            id: noOfItems.length + 1,
            specimen_entry_id: props.specimenEntryID,
            labTestID: "",
            testSpecimenID: "",
            item_name: "",
            si_unit: "",
            ref_interval: "Single",
            minimum_interval: "",
            maximum_interval: "",
            ref_interval_one: "",
            ref_interval_two: "",
        };
        const initialRef1 = {
            id: 1,
            key: noOfItems.length + 1,
            interval_type: "",
            main_ref_title: "",
            ref_interval_name: "",
            minimum_interval: "",
            maximum_interval: "",
        }

        const initialRef2 = {
            id: 1,
            key: noOfItems.length + 1,
            interval_type: "",
            main_ref_title: "",
            ref_interval_name: "",
            minimum_interval: "",
            maximum_interval: "",
        }

        setItemFormData((prevState) => [...prevState, initialValue]);
    };

    const onDeleteItem = (id) => {
        const filteredFormDataItems = itemFormData.filter(e=>e.id !== id)
        setItemFormData(filteredFormDataItems)
        const filteredItems = noOfItems.filter(e=>e.id !== id)
        setNoOfItems(filteredItems)
    }

    // useEffect(() => {
    //     const initializeFormData = () => {
    //         // Create new form data entries only for new items
    //         const newFormData = noOfItems.map((item) => ({
    //             id: item.id,
    //             specimen_entry_id: props.specimenEntryID,
    //             labTestID: "",
    //             testSpecimenID: "",
    //             item_name: "",
    //             si_unit: "",
    //             ref_interval: "Single",
    //             minimum_interval: "",
    //             maximum_interval: "",
    //             ref_interval_one: "",
    //             ref_interval_two: "",
    //         }));
    //
    //         // Set the new form data, keeping the previous state
    //         setItemFormData((prevState) => {
    //             // Filter out the already existing items to avoid duplicates
    //             const existingIDs = prevState.map((item) => item.id);
    //             const filteredNewFormData = newFormData.filter(
    //                 (item) => !existingIDs.includes(item.id)
    //             );
    //             return [...prevState, ...filteredNewFormData];
    //         });
    //     };
    //
    //     initializeFormData();
    // }, [noOfItems]);

    useEffect(() => {
        const initializeFormData = () => {
            // Create new form data entries only for new items
            const newFormData = noOfItems.map((item) => ({
                id: noOfItems.length + 1,
                specimen_entry_id: props.specimenEntryID,
                labTestID: "",
                testSpecimenID: "",
                item_name: "",
                si_unit: "",
                ref_interval: "Single",
                minimum_interval: "",
                maximum_interval: "",
                ref_interval_one: "",
                ref_interval_two: "",
            }));

            // Set the new form data, keeping the previous state
            setItemFormData((prevState) => {
                // Filter out the already existing items to avoid duplicates
                const existingIDs = prevState.map((item) => item.id);
                const filteredNewFormData = newFormData.filter(
                    (item) => !existingIDs.includes(item.id)
                );
                return [...prevState, ...filteredNewFormData];
            });
        };

        initializeFormData();
    }, [props.rebuildOnAddSpecimen]);


    const renderItems = () => {
        return noOfItems.map((item, index)=>(
            <div key={index} className="col-md-10 offset-sm-1">
                <div className="d-flex no-block align-items-center mt-3 ">
                    <h3><strong> <FaFlaskVial/> {item.name} {index+1}</strong></h3>
                    <div className="ml-auto">
                        <div className="btn-group">
                            {
                                index === 0 ? <></> :
                                    <button onClick={()=>onDeleteItem(item.id)} className="btn btn-danger">
                                        <i className="fa fa-trash-alt"></i>
                                    </button>
                            }

                        </div>
                    </div>
                </div>
                <hr/>
                <div className="col-md-12 shadow p-2 mb-4">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <th>Item Name</th>
                            <td><input name="item_name" className="form-control" id="item_name" value={itemFormData[index]?.item_name} onChange={(e)=>onItemEdit(e, index, props.specimenEntryID)} placeholder="Sodium" /></td>
                        </tr>
                        <tr>
                            <th>Item Name</th>
                            <td>
                                <select className="form-control" name="si_unit" id="si_unit" value={itemFormData[index]?.si_unit} onChange={(e)=>onItemEdit(e, index, props.specimenEntryID)}>
                                    <option value="">Select SI Unit</option>
                                    {
                                        SIUnitList.length > 0 && SIUnitList.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Reference Interval</th>
                            <td>
                                <select className="form-control" name="ref_interval" id="ref_interval"
                                        value={itemFormData[index]?.ref_interval} onChange={(e)=>onItemEdit(e, index, props.specimenEntryID)}>
                                    <option value="Single">Single Entry</option>
                                    {/*<option value="Dual">Dual Entry</option>*/}
                                </select>
                            </td>
                        </tr>
                        {
                            itemFormData[index]?.ref_interval === "Single" ?
                                <tr>
                                    <th>Reference Interval Value</th>
                                    <td>
                                        <div className="d-flex justify-content-between">
                                            <input type="number" name="minimum_interval" className="form-control mr-1" id="minimum_interval" value={itemFormData[index]?.minimum_interval} onChange={(e)=>onItemEdit(e, index, props.specimenEntryID)} placeholder="Minimum Interval" />
                                            <input type="number" name="maximum_interval" className="form-control ml-1" id="maximum_interval" value={itemFormData[index]?.maximum_interval} onChange={(e)=>onItemEdit(e, index, props.specimenEntryID)} placeholder="Maximum Interval" />
                                        </div>
                                    </td>
                                </tr> : <></>
                        }

                        </tbody>
                    </table>

                    {
                        itemFormData[index]?.ref_interval === "Single" ? <></> :
                            <div className="col-md-12">
                                <h3>Reference Interval</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-12 form-group mb-4">
                                            <label>Ref Interval 1</label>
                                            <select className="form-control" name="ref_interval_one" id="ref_interval_one" value={itemFormData[index]?.ref_interval_one} onChange={(e)=>onItemEdit(e, index, props.specimenEntryID)}>
                                                <option value="">Select Option</option>
                                                <option value="Single">Single Entry</option>
                                                <option value="Two">Two Entries</option>
                                                <option value="Three">Three Entries</option>
                                                <option value="Four">Four Entries</option>
                                            </select>
                                        </div>
                                        {
                                            refIntervalArray1.length > 1 && <div className="col-md-12 form-group mb-4">
                                                <label>Main Ref Title</label>
                                                <input name="main_ref_title" className="form-control" id="main_ref_title"
                                                       value={refFormData[0]?.main_ref_title}
                                                       onChange={(e) => onRefMainTitleEdit(e, index, props.specimenEntryID)} placeholder="Ref Title"/>
                                            </div>
                                        }
                                        {
                                            refIntervalArray1.map((_, int)=> {
                                                return <div key={int} className="col-md-12 form-group mb-4">
                                                    <label>Ref Interval Name {int+1}</label>
                                                    <input name="ref_interval_name" className="form-control mb-2"
                                                           id="ref_interval_name"
                                                           value={refFormData[int]?.ref_interval_name}
                                                           onChange={(e) => onRefEdit(e, int, index)}
                                                           placeholder="Enter Ref Title  Name"/>
                                                    <div className="d-flex justify-content-between">
                                                        <input type="number" name="minimum_interval"
                                                               className="form-control mr-1" id="minimum_interval"
                                                               value={refFormData[int]?.minimum_interval}
                                                               onChange={(e) => onRefEdit(e, int, index)}
                                                               placeholder="Minimum Interval"/>
                                                        <input type="number" name="maximum_interval"
                                                               className="form-control ml-1" id="maximum_interval"
                                                               value={refFormData[int]?.maximum_interval}
                                                               onChange={(e) => onRefEdit(e, int, index)}
                                                               placeholder="Maximum Interval"/>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 form-group mb-4">
                                            <label>Ref Interval 1</label>
                                            <select className="form-control" name="ref_interval_two"
                                                    id="ref_interval_two" value={itemFormData[index]?.ref_interval_two}
                                                    onChange={(e) => onItemEdit(e, index)}>
                                                <option value="">Select Option</option>
                                                <option value="Single">Single Entry</option>
                                                <option value="Two">Two Entries</option>
                                                <option value="Three">Three Entries</option>
                                                <option value="Four">Four Entries</option>
                                            </select>
                                        </div>
                                        {
                                            refIntervalArray2.length > 1 && <div className="col-md-12 form-group mb-4">
                                                <label>Main Ref Title</label>
                                                <input name="main_ref_title" className="form-control" id="main_ref_title"
                                                       value={itemFormData[0]?.main_ref_title}
                                                       onChange={(e) => onRefMainTitleEdit2(e, index)} placeholder="Ref Title"/>
                                            </div>
                                        }
                                        {
                                            refIntervalArray2?.map((item, int) => {
                                                return <div key={int} className="col-md-12 form-group mb-4">
                                                    <label>Ref Interval Name {int + 1}</label>
                                                    <input name="ref_interval_name" className="form-control mb-2"
                                                           id="ref_interval_name"
                                                           value={refFormData2[int]?.ref_interval_name}
                                                           onChange={(e) => onRefEdit2(e, int, index)}
                                                           placeholder="Enter Ref Title  Name"/>
                                                    <div className="d-flex justify-content-between">
                                                        <input type="number" name="minimum_interval"
                                                               className="form-control mr-1" id="minimum_interval"
                                                               value={refFormData2[int]?.minimum_interval}
                                                               onChange={(e) => onRefEdit2(e, int, index)}
                                                               placeholder="Minimum Interval"/>
                                                        <input type="number" name="maximum_interval"
                                                               className="form-control ml-1" id="maximum_interval"
                                                               value={refFormData2[int]?.maximum_interval}
                                                               onChange={(e) => onRefEdit2(e, int, index)}
                                                               placeholder="Maximum Interval"/>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                    }

                </div>

            </div>
        ))
    };

    return <div>
        {renderItems()}
        <hr/>
        <button onClick={onAddItem} className="btn btn-info mb-3">Add Item</button>
    </div>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(SpecimenItem);
