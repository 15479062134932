import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../../url";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../../common/loader";
import {toast} from "react-toastify";
function ObstetricHistory(props) {
    const { patientSerial, antenatalID, patientName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const initialValues = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        antenatalID: "",
        pregnancyID: "",
        gravidity: "",
        parity: "",
        noOfChildren: "",
        LMP: "",
        EDD: "",
        gestationalAge: "",
        stillBirth: "",
        abortion: "",
        CS: "",
        remark: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(initialValues)

    const resetFormData = () => {
        setFormData(initialValues)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const addPregnancy = async () => {
        if (formData.year.toString().trim() === ''){
            toast.error('Please select pregnancy year')
            return false
        }
        if (formData.duration.toString().trim() === ''){
            toast.error('Please select pregnancy duration')
            return false
        }
        if (formData.complications.toString().trim() === ''){
            toast.error('Please select pregnancy complications')
            return false
        }
        if (formData.labour.toString().trim() === ''){
            toast.error('Please select pregnancy labour')
            return false
        }
        if (formData.isAlive.toString().trim() === ''){
            toast.error('Please select if baby is alive/dead')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.post(`${serverLink}obstetric-history/pregnancy/add`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Obstetric History Added Successfully");
                setIsFormLoading(false);
                props.setRebuildPregnancy(generate_token(5));
                document.getElementById("closeObstetric").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding obstetric. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Gravidity</label>
                                <select
                                    id="gravidity"
                                    name="gravidity"
                                    className="form-control"
                                    value={formData.gravidity}
                                    onChange={onEdit}>
                                    <option value="0-Nulligravida - Never been pregnant">0 (Nulligravida - Never been
                                        pregnant)
                                    </option>
                                    <option value="1-Primigravida - First pregnancy">1 (Primigravida - First
                                        pregnancy)
                                    </option>
                                    <option value="2-Secundigravida - Second pregnancy">2 (Secundigravida - Second
                                        pregnancy)
                                    </option>
                                    <option value="3-Multigravida - Multiple pregnancies">3 (Multigravida - Multiple
                                        pregnancies)
                                    </option>
                                    <option value="4+">4+</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Parity</label>
                                <select
                                    id="parity"
                                    name="parity"
                                    className="form-control"
                                    value={formData.parity}
                                    onChange={onEdit}>
                                    <option value="0-Nulliparous - Never given birth">0 (Nulliparous - Never given
                                        birth)
                                    </option>
                                    <option value="1-Primiparous - Given birth once">1 (Primiparous - Given birth
                                        once)
                                    </option>
                                    <option value="2-Multiparous - Given birth twice">2 (Multiparous - Given birth
                                        twice)
                                    </option>
                                    <option value="3+">3+</option>
                                </select>

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">No Of Children</label>
                                <input
                                    type="number"
                                    id="noOfChildren"
                                    name="noOfChildren"
                                    className="form-control"
                                    value={formData.noOfChildren}
                                    min={0}
                                    onChange={onEdit}/>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label"> LMP</label>
                                <input
                                    type="date"
                                    name="LMP"
                                    id="LMP"
                                    className="form-control"
                                    value={formData.LMP}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label"> EDD</label>
                                <input
                                    type="date"
                                    name="EDD"
                                    id="EDD"
                                    className="form-control"
                                    value={formData.EDD}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Gestational Age</label>
                                <select
                                    id="isAligestationalAgeve"
                                    name="gestationalAge"
                                    className="form-control"
                                    value={formData.gestationalAge}
                                    onChange={onEdit}>
                                    <option value="0-12 - weeks (First Trimester)">0-12 weeks (First Trimester)</option>
                                    <option value="13-26 - weeks (Second Trimester)">13-26 weeks (Second Trimester)
                                    </option>
                                    <option value="27-40 - weeks (Third Trimester)">27-40 weeks (Third Trimester)
                                    </option>
                                    <option value="41+ weeks">41+ weeks</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Still Birth</label>
                                <select
                                    id="stillBirth"
                                    name="stillBirth"
                                    className="form-control"
                                    value={formData.stillBirth}
                                    onChange={onEdit}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Abortion</label>
                                <select
                                    id="abortion"
                                    name="abortion"
                                    className="form-control"
                                    value={formData.abortion}
                                    onChange={onEdit}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Abortion</label>
                                <select
                                    id="CS"
                                    name="CS"
                                    className="form-control"
                                    value={formData.CS}
                                    onChange={onEdit}>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Remark</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="remark"
                                    className="form-control"
                                    id="remark"
                                    value={formData.remark}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addPregnancy}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(ObstetricHistory);