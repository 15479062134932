import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import "./style.css";
import {imageExists, projectLogo, serverLink} from "../../url";
import placeholderImage from "../../img/male_avater.jpg";

function UserIDCard(props) {
    const { UserID, UserName, Role, Passport} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const componentRef = useRef();
    const [printPage, setPrintPage] = useState(false);

    const onPrintPage = () => {
        setPrintPage(true);
        setTimeout(() => {
            handlePrint();
            setPrintPage(false);
        }, 1000);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content" >
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div style={{ marginTop: '60px' }} className=""  ref={componentRef}>
                            {/* Front side Start , border: '1px solid #000'    style={{ width: '3.366in', height: '2.11in',*/}

                            {/* Front side ends*/}
                            <div className="break-page mb-3 d-flex justify-content-center">
                                <div className="id-card-portrait">
                                    <div style={{ height: '6.000in', width: '3.70in', border: '15px solid #e61b1d'}}>

                                        <div style={{ position: 'relative', height: '100%' }}>
                                            <div style={{ position: 'absolute', top: '5%',   left: '50%', transform: 'translateX(-50%)', textAlign: 'center'}}>
                                                <img width={200}  src={projectLogo}/>
                                            </div>
                                            <div style={{ position: 'absolute', top: '25%',   left: '50%', transform: 'translateX(-50%)', textAlign: 'center'}}>
                                                <img width={180} style={{marginBottom: '5px'}}  src={imageExists(`${serverLink}public/uploads/staff_uploads/${Passport}`) ? `${serverLink}public/uploads/staff_uploads/${Passport}` : placeholderImage}/>
                                                <b style={{ fontSize: '18px', color: '#000'}}>{UserName ?? ""}</b><br/>
                                                <span style={{ fontSize: '16px', color: '#E61B1DFF' }}>{Role ?? ""}</span><br/>
                                                <span style={{ fontSize: '16px', color: '#000' }}>{UserID ?? ""}</span>
                                            </div>
                                            <div style={{ position: 'absolute', bottom: '9%',   left: '50%', transform: 'translateX(-50%)', textAlign: 'center'}}>
                                                <QRCode
                                                    size={90}
                                                    value={UserID ?? ""}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                <br />
                                            </div>
                                            <div style={{ fontSize: '8px', position: 'absolute', bottom: 0, left: 0, width: '100%',  padding: '10px', backgroundColor: '#e61b1d', color: 'white', textAlign: 'center' }}>
                                                <b style={{ padding: '10px'}}>Address: No. 12 Ngozi Avenue off Omelazu Street by Faulks Road Aba.</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Back side start*/}
                            <div className="break-page mb-3  d-flex justify-content-center align-items-center">
                                <div className="id-card-portrait">
                                    <div style={{ display: 'flex', flexDirection: 'row' , width: '3.675in', border: '15px solid #e61b1d'}}>
                                        <div style={{ width: '6.366in', height: '5.11in', backgroundImage: `url(${require('../../../images/card_back.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Back side ends*/}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onPrintPage}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Print Card"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(UserIDCard);