import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import PatientPayments from "../../patient/utils/patient-payments";
import PatientServices from "../../patient/utils/patient-services";
import Allergy from "../../patient/components/allergy";
import RequestService from "../../patient/components/service-request";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {PatientInfoCard} from "../../patient/utils/patient-info-card";

function WorkingServiceDashboard(props) {
    const token = props.loginData[0]?.token;
    const {slug} = useParams();
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(props.patientDetails)
    const [rebuildAllergy, setRebuildAllergy] = useState(null);
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const [rebuildServiceRequest, setRebuildServiceRequest] = useState(null);

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}working-service/${atob(slug)}`, token)
            .then(async (result) => {
                const patient = result.data;
                if (patient.length > 0){
                    setPatientDetails(patient[0])
                }else{
                    navigate('/')
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Working Service Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Working Service Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-allergies-tab" data-toggle="pill"
                                           href="#allergies" role="tab" aria-controls="pills-allergies"
                                           aria-selected="false">Allergies</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-payments-tab" data-toggle="pill"
                                           href="#payments"
                                           role="tab" aria-controls="pills-payments" aria-selected="false">Payments</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-services-tab" data-toggle="pill"
                                           href="#services"
                                           role="tab" aria-controls="pills-services" aria-selected="false">Services</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCard patientDetails={patientDetails}/>
                                                <div className="col-md-8">
                                                    {/*<h4 className="card-title m-t-5">Menu</h4>*/}
                                                    <div className="row">
                                                        <MenuCard title="Add Allergy" icon="fa-briefcase-medical"
                                                                  size="col-md-3" color="orange" modal="allergy-modal"/>
                                                        <MenuCard title="Service Request" icon="fa-cogs" size="col-md-3"
                                                                  color="danger" modal="service-modal"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="pills-payments-tab">
                                        <div className="card-body">
                                            <PatientPayments patientSerial={patientDetails.patientSerial} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="services" role="tabpanel" aria-labelledby="pills-services-tab">
                                        <div className="card-body">
                                            <PatientServices patientSerial={patientDetails.patientSerial} handleRemount={rebuildServiceRequest}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Allergy
                    id="allergy-modal"
                    title="Add Allergy"
                    close="closeAllergy"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={0}
                    setRebuildAllergy={setRebuildAllergy}
                />

                <RequestService
                    id="service-modal"
                    title="Request Service"
                    close="closeServiceRequest"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={0}
                    patientEmail={patientDetails.emailAddress}
                    setRebuildPayment={setRebuildPayment}
                    setRebuildServiceRequest={setRebuildServiceRequest}
                />

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(WorkingServiceDashboard);

