import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../../url";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../../common/loader";
import {toast} from "react-toastify";
function TakeSample(props) {
    const { patientSerial, patientName, walletAmount, patientDetails, setRebuildDashboard, setRebuildLab} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)

    const Initial = {
        patientSerial: patientSerial,
        patientName: patientName,
        doctorID: `${props.loginData[0]?.userID}`,
        labTestRequestID: patientDetails.labTestRequestID,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    const takeSample = async () => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            testStatus: 'Specimen Taken',
        }

        await axios.post(`${serverLink}laboratory/test/specimen/taken`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Specimen Taken Successfully");
                setIsFormLoading(false);
                setRebuildDashboard(generate_token(5));
                setRebuildLab(generate_token(5));
                document.getElementById("closeSample").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong taking specimen. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Specimen</th>
                                                        <th>Bottle</th>
                                                        <th>Volume</th>
                                                        <th>Preparation</th>
                                                        <th>Turn Around</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenName}</td>
                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenBottle}</td>
                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenBottleVolume}</td>
                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenTurnAroundTime}</td>
                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenPatientPreparation}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={takeSample}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(TakeSample);