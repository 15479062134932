import React, {useEffect, useState} from "react";
import {formatDate, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";
import MakePayment from "../../finance/choose-payment/choose-payment";
import AntenatalCarePackageForm from "./antenatal-care-package-form";
function AntenatalRegistration(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(true)
    const [patient, setPatient] = useState([])
    const header = [
        "S/N",
        "Patient ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "Action",
    ];

    const today = new Date(Date.now());
    const start_date = formatDate(today);
    let end_date = new Date(new Date().setFullYear(today.getFullYear() + 1))
     end_date = formatDate(new Date(end_date.setDate(end_date.getDate() - 1)));

    const Initial = {
        patientSerial: '',
        patientName: '',
        search: "",
        walletAmount: 0,
        amount: 40000,
        packageName: 'Antenatal Regular Package',
        visitDate: '',
        startDate: start_date,
        expiryDate: end_date,
        remark: '',
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)
    const resetFormData = () => {
        setFormData(Initial)
    }

    const showTable = () => {
        try {
            return patient.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>
                            <a
                                href="#"
                                className={"btn btn-info btn-sm "}
                                data-toggle="modal"
                                data-target="#package-modal"
                                onClick={() =>
                                    showConfirm(
                                        "Warning",
                                        `Are you sure you want to register this patient for antenatal?`,
                                        "warning"
                                    ).then(async (confirm) => {
                                        if (confirm) {
                                            setFormData({
                                                ...formData,
                                                patientSerial: item.patientSerial,
                                                patientName: `${item.firstName} ${item.middleName} ${item.surname}`,
                                                walletAmount: item.walletAmount,
                                            })
                                        }
                                    })
                                }
                            >
                                Enrol
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onSearchPatient = async (e) => {
        setIsGettingSchedules(true)
        const val = e.target.value;
        setFormData({
            ...formData,
            search: val,
        })
        await axios.get(`${serverLink}patient/dependant/${val}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatient(result.data.filter(e=>e.patientSerial !== formData.patientSerial));
                }else {
                    setPatient([])
                }
                setIsGettingSchedules(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const register = async (paymentData) => {
        const requestData = {
            ...formData,
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}antenatal/register`, requestData, token).then(async (result) => {
            if (result.data.message === "success") {
                let item_id = result.data.antenatalID;
                const sendData = {...paymentData,  itemID: item_id}
                await axios.post(`${serverLink}payment/post-payment`, sendData, token).then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Antenatal Registration Successfully");
                        setIsFormLoading(false);
                        document.getElementById("closePackage").click();
                        document.getElementById("closeAntenatalPayment").click();
                        props.closeRegForm();
                        setPatient([])
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        toast.error("Something went wrong posting payment. Please try again!");
                    }
                })
            }else if (result.data.message === "exist") {
                toast.error("The selected patient have an active antenatal registration");
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding patient to antenatal. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handlePayment = () => {
        if (formData.visitDate === ""){
            toast.error('Please select visiting date')
            return false
        }
        document.getElementById('pay-now').click();
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`} id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Search Patient</label>
                                <input
                                    name="search"
                                    className="form-control"
                                    id="search"
                                    value={formData.search}
                                    onChange={onSearchPatient}
                                    placeholder="Search patient by patientID, name, email or phone number"
                                />
                            </div>

                            {
                                IsGettingSchedules ? <></> :
                                    <div className="mb-3 table-responsive">
                                        <DataTable tableID="patientList" header={header} body={showTable()}
                                                   title="patient List"/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AntenatalCarePackageForm
            id="package-modal"
            title="Antenatal Package form"
            close="closePackage"
            formData={formData}
            setFormData={setFormData}
            onEdit={onEdit}
            onSubmit={handlePayment}
            IsFormLoading={IsFormLoading}
        />
        <button data-toggle="modal" style={{display: 'none'}} id="pay-now" data-target={`#payment-modal`}>Open
        </button>
        {
            formData.patientSerial !=="" ?
                <MakePayment
                    id="payment-modal"
                    title="Make Payment"
                    close="closeAntenatalPayment"
                    patientSerial={formData.patientSerial}
                    patientName={formData.patientName}
                    walletAmount={formData.walletAmount}
                    paymentFor="Antenatal Registration"
                    itemName={formData.packageName}
                    itemAmount={formData.amount}
                    paymentType="Debit"
                    IsFormLoading={IsFormLoading}
                    onPostPayment={register}
                    onError={() => {
                        console.log("Error")
                    }}
                />
                :
                <></>
        }

    </>
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(AntenatalRegistration);