import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../../url";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../../common/loader";
import {toast} from "react-toastify";
import ImageUploader from "../../../patient/components/image-uploader";
import FileUploader from "../../../patient/components/file-uploader";
function EnterTestResult(props) {
    const { patientSerial, patientName, patientDetails, setRebuildDashboard, setRebuildLab} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [resultItem, setResultItem] = useState(patientDetails.items)

    const Initial = {
        patientSerial: patientSerial,
        patientName: patientName,
        doctorID: `${props.loginData[0]?.userID}`,
        labTestRequestID: patientDetails.labTestRequestID,
        requestTypeID: patientDetails.requestID,
        requestType: patientDetails.requestType,
        ImagePath: '',
        ImageData: '',
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    const submitResult = async () => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            resultData: resultItem,
            testStatus: 'Result Entered',
        }

        await axios.post(`${serverLink}laboratory/test/result/entry`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                if (formData.ImagePath !== "") {
                    const formData2 = new FormData();
                    formData2.append('photo', formData.ImagePath);
                    formData2.append('entry_id', patientDetails.labTestRequestID)
                    axios.patch(`${serverLink}laboratory/test/result/upload`, formData2)
                        .then(async (result) => {
                            toast.success("Result Entered Successfully");
                            setIsFormLoading(false);
                            setRebuildDashboard(generate_token(5));
                            setRebuildLab(generate_token(5));
                            document.getElementById("closeResult").click();
                            resetFormData()
                        })
                        .catch(err => {
                            setIsFormLoading(false);
                            showAlert(
                                "ERROR",
                                "Something went wrong uploading the image. Please try again!",
                                "error"
                            );
                        });
                }else{
                    toast.success("Result Entered Successfully");
                    setIsFormLoading(false);
                    setRebuildDashboard(generate_token(5));
                    document.getElementById("closeResult").click();
                    resetFormData()
                }
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong entering payment. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const onEdit = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        let item_id = e.target.getAttribute('item_id');
        const filteredItem = patientDetails.items.filter((e=>e.specimenItemID === parseInt(item_id)))
        const item = {...filteredItem[0], result: value }
        const otherItems = resultItem.filter((e=>e.specimenItemID  !== parseInt(item_id)))
        setResultItem([item, ...otherItems])
    };

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            {
                                                patientDetails.items.map((item, index)=>{
                                                    return(
                                                        <div key={index} className="mb-3">
                                                            <h4><b>Item Name:</b> {item.item_name} => <b>SI Unit:</b> {item.si_unit}</h4>
                                                            <input id={`item-${index}`} name={`input[]`} item_id={item.specimenItemID} className="form-control" required="required"  onChange={onEdit}/>
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <span className="alert alert-info">Minimum Reference Interval: {item.minimum_interval}</span>
                                                                <span className="alert alert-danger">Maximum Reference Interval: {item.maximum_interval}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="mb-3 form-group col-md-12">
                                                <FileUploader formData={formData} setFormData={setFormData} title={"Upload Result File (Optional)"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={submitResult}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(EnterTestResult);