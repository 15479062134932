import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../../url";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../../common/loader";
import {toast} from "react-toastify";
function Pregnancy(props) {
    const { patientSerial, antenatalID, patientName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const allergyInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        antenatalID: "",
        year: "",
        complications: "",
        labour: "",
        isAlive: "",
        ageAtDeath: "",
        causeOfDeath: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(allergyInitial)

    const resetFormData = () => {
        setFormData(allergyInitial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const addPregnancy = async () => {
        if (formData.year.toString().trim() === ''){
            toast.error('Please select pregnancy year')
            return false
        }
        if (formData.duration.toString().trim() === ''){
            toast.error('Please select pregnancy duration')
            return false
        }
        if (formData.complications.toString().trim() === ''){
            toast.error('Please select pregnancy complications')
            return false
        }
        if (formData.labour.toString().trim() === ''){
            toast.error('Please select pregnancy labour')
            return false
        }
        if (formData.isAlive.toString().trim() === ''){
            toast.error('Please select if baby is alive/dead')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.post(`${serverLink}antenatal/pregnancy/add`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Pregnancy Added Successfully");
                setIsFormLoading(false);
                props.setRebuildPregnancy(generate_token(5));
                document.getElementById("closePregnancy").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding Pregnancy. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Year</label>
                                <select
                                    id="year"
                                    name="year"
                                    className="form-control"
                                    value={formData.year}
                                    onChange={onEdit}>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Duration</label>
                                <select
                                    id="duration"
                                    name="duration"
                                    className="form-control"
                                    value={formData.duration}
                                    onChange={onEdit}>
                                    <option value="preterm">Preterm (&lt; 37 weeks)</option>
                                    <option value="term">Term (37-42 weeks)</option>
                                    <option value="post-term">Post-term (&gt; 42 weeks)</option>
                                </select>

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Complications</label>
                                <select
                                    id="complications"
                                    name="complications"
                                    className="form-control"
                                    value={formData.complications}
                                    onChange={onEdit}>
                                    <option value="None">None</option>
                                    <option value="Gestational Diabetes">Gestational Diabetes</option>
                                    <option value="Preeclampsia">Preeclampsia</option>
                                    <option value="Placenta Previa">Placenta Previa</option>
                                    <option value="Preterm Labour">Preterm Labour</option>
                                    <option value="IUGR">Intrauterine Growth Restriction (IUGR)</option>
                                    <option value="Multiple Pregnancy">Multiple Pregnancy (Twins, Triplets, etc.)
                                    </option>
                                    <option value="Anemia">Anemia</option>
                                    <option value="Hypertension">Hypertension</option>
                                    <option value="Oligohydramnios">Oligohydramnios</option>
                                    <option value="Polyhydramnios">Polyhydramnios</option>
                                    <option value="Infection">Infection</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label"> Labour</label>
                                <select
                                    id="labour"
                                    name="labour"
                                    className="form-control"
                                    value={formData.labour}
                                    onChange={onEdit}>
                                    <option value="Spontaneous">Spontaneous</option>
                                    <option value="Induced">Induced</option>
                                    <option value="CS">Cesarean Section (CS)</option>
                                    <option value="Vacuum Assisted">Vacuum-Assisted</option>
                                    <option value="Forceps Assisted">Forceps-Assisted</option>
                                    <option value="Elective CS">Elective Cesarean</option>
                                    <option value="Emergency CS">Emergency Cesarean</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Is Alive?</label>
                                <select
                                    id="isAlive"
                                    name="isAlive"
                                    className="form-control"
                                    value={formData.isAlive}
                                    onChange={onEdit}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Age At Death</label>
                                <select
                                    id="ageAtDeath"
                                    name="ageAtDeath"
                                    className="form-control"
                                    value={formData.ageAtDeath}
                                    onChange={onEdit}>
                                    <option value="stillbirth">Stillbirth</option>
                                    <option value="neonatal">Neonatal (&lt; 28 days)</option>
                                    <option value="infant">Infant (1-12 months)</option>
                                    <option value="child">Child (1-5 years)</option>
                                    <option value="adolescent">Adolescent (6-17 years)</option>
                                    <option value="adult">Adult (18+ years)</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Cause Of Death</label>
                                <select
                                    id="causeOfDeath"
                                    name="causeOfDeath"
                                    className="form-control"
                                    value={formData.causeOfDeath}
                                    onChange={onEdit}>
                                    <option value="birth_asphyxia">Birth Asphyxia</option>
                                    <option value="preterm_complications">Preterm Birth Complications</option>
                                    <option value="congenital_anomalies">Congenital Anomalies</option>
                                    <option value="infections">Infections (e.g., Sepsis, Pneumonia)</option>
                                    <option value="sids">Sudden Infant Death Syndrome (SIDS)</option>
                                    <option value="accident">Accident</option>
                                    <option value="malnutrition">Malnutrition</option>
                                    <option value="other_conditions">Other Medical Conditions</option>
                                    <option value="unknown">Unknown</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addPregnancy}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(Pregnancy);