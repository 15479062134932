import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {formatDate, formatDateAndTime, moneyFormat, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";

import Loader from "../../common/loader";
import DrugCategoryForm from "./drug-category-form";
import DrugSubCategoryForm from "./drug-sub-category-form";
import ManufacturerForm from "./manufacturer";
import DrugForm from "./drug-form";
import DrugSubSubCategoryForm from "./drug-sub-sub-category-form";


function Drugs(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [drugCategoryList, setDrugCategoryList] = useState([])
    const [drugSubCategoryList, setDrugSubCategoryList] = useState([])
    const [drugSubSubCategoryList, setDrugSubSubCategoryList] = useState([])
    const [manufacturerList, setManufacturerList] = useState([])
    const [drugList, setDrugList] = useState([])

    const header = ["S/N", "Drug Category",  "Description", "Added By", "Action"];
    const header3 = ["S/N", "Drug Category","Drug Sub Category",  "Description", "Added By", "Action"];
    const header4 = ["S/N", "Drug Category","Drug Sub Category", "Drug Sub Sub Category",  "Description", "Added By", "Action"];
    const header5 = ["S/N", "Manufacturer Name", "Phone Number", "Email Address", "Address",  "Description", "Added By", "Action"];
    const header6 = ["S/N", "Drug Name", "Category", "Manufacturer", "Price (₦)",  "Quantity", "Drug Description", "Added By", "Action"];
    const header7 = ["S/N", "Drug Name", "Category", "Manufacturer", "Price (₦)",  "Quantity", "Expiry Date", "Status"];
    const header8 = ["S/N", "Drug Name", "Category", "Manufacturer",  "Quantity", "Stock Price (₦)", "Total Stock Price (₦)", "Sells Price (₦)",  "Total Sells Price (₦)",  "expected  Profit (₦)", "Updated Date"];

    const categoryInitial = {
        drugCategoryID: "",
        categoryName: "",
        description: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const subCategoryInitial = {
        drugSubcategoryID: "",
        drugCategoryID: "",
        subCategoryName: "",
        description: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const subSubCategoryInitial = {
        drugSubSubcategoryID: "",
        drugSubcategoryID: "",
        drugCategoryID: "",
        subSubCategoryName: "",
        description: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const manufacturerInitial = {
        manufacturerID: "",
        manufacturerName: "",
        address: "",
        phoneNumber: "",
        emailAddress: "",
        description: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const drugInitial = {
        drugID: "",
        drugName: "",
        drugCategoryID: "",
        drugSubcategoryID: "",
        drugSubSubcategoryID: "",
        manufacturerID: "",
        manufactureDate: "",
        drugStockPrice: "",
        drugPrice: "",
        drugQuantity: "",
        packSize: "",
        drugQRCode: "",
        drugRow: "",
        drugDescription: "",
        expiryDate: "",
        drugStatus: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    
    const [formData, setFormData] = useState(categoryInitial)
    const [formData2, setFormData2] = useState(subCategoryInitial)
    const [formData4, setFormData4] = useState(subSubCategoryInitial)
    const [formData5, setFormData5] = useState(manufacturerInitial)
    const [formData6, setFormData6] = useState(drugInitial)

    const resetFormData = () => setFormData(categoryInitial)
    const resetFormData2 = () => setFormData2(subCategoryInitial)
    const resetFormData4 = () => setFormData4(subSubCategoryInitial)
    const resetFormData5 = () => setFormData5(manufacturerInitial)
    const resetFormData6 = () => setFormData6(drugInitial)

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/pharmacy/drug/data`, token)
            .then((result) => {
                setDrugCategoryList(result.data.category);
                setDrugSubCategoryList(result.data.subCategory);
                setDrugSubSubCategoryList(result.data.subSubCategory);
                setManufacturerList(result.data.manufacturer);
                setDrugList(result.data.drug);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const getExpiryDate = (expiryDateStr) => {
        const expiryDate = new Date(expiryDateStr);
        const currentDate = new Date();
        if (currentDate > expiryDate) {
            return <div className="badge badge-danger text-center">Expired</div>;
        } else {
            return <div className="badge badge-success text-center">Active</div>;
        }

    }

    const  showTable = () => {
        try {
            return drugCategoryList.length > 0 && drugCategoryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.description}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#category-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       drugCategoryID: item.drugCategoryID,
                                       categoryName: item.categoryName,
                                       description: item.description,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable3 = () => {
        try {
            return drugSubCategoryList.length > 0 &&  drugSubCategoryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.subCategoryName}</td>
                        <td>{item.description}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#sub-category"
                               onClick={() => {
                                   setFormData2({
                                       ...formData2,
                                       drugSubcategoryID: item.drugSubcategoryID,
                                       drugCategoryID: item.drugCategoryID,
                                       subCategoryName: item.subCategoryName,
                                       description: item.description,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable4 = () => {
        try {
            return drugSubSubCategoryList.length > 0 &&  drugSubSubCategoryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.subCategoryName}</td>
                        <td>{item.subSubCategoryName}</td>
                        <td>{item.description}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#sub-sub-category"
                               onClick={() => {
                                   setFormData4({
                                       ...formData4,
                                       drugSubSubcategoryID: item.drugSubSubcategoryID,
                                       drugSubcategoryID: item.drugSubcategoryID,
                                       drugCategoryID: item.drugCategoryID,
                                       subSubCategoryName: item.subSubCategoryName,
                                       description: item.description,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable5 = () => {
        try {
            return manufacturerList.length > 0 &&  manufacturerList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.manufacturerName}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>{item.address}</td>
                        <td>{item.description}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#manufacturer-modal"
                               onClick={() => {
                                   setFormData5({
                                       ...formData5,
                                       manufacturerID: item.manufacturerID,
                                       manufacturerName: item.manufacturerName,
                                       phoneNumber: item.phoneNumber,
                                       emailAddress: item.emailAddress,
                                       address: item.address,
                                       description: item.description,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable6 = () => {
        try {
            return drugList.length > 0 &&  drugList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.drugName}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.manufacturer}</td>
                        <td>{item.drugPrice}</td>
                        <td>{item.drugQuantity}</td>
                        <td>{item.drugDescription}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#drug-model"
                               onClick={() => {
                                   setFormData6({
                                       ...formData6,
                                       drugID: item.drugID,
                                       drugName: item.drugName,
                                       drugCategoryID: item.drugCategoryID,
                                       drugSubcategoryID: item.drugSubcategoryID,
                                       drugSubSubcategoryID: item.drugSubSubcategoryID,
                                       manufacturerID: item.manufacturerID,
                                       manufactureDate: item.manufactureDate,
                                       drugStockPrice: item.drugStockPrice,
                                       drugPrice: item.drugPrice,
                                       drugQuantity: item.drugQuantity,
                                       packSize: item.packSize,
                                       drugQRCode: item.drugQRCode,
                                       drugDescription: item.drugDescription,
                                       drugRow: item.drugRow,
                                       drugStatus: item.drugStatus,
                                       expiryDate: item.expiryDate,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable7 = () => {
        try {
            const currentDate = new Date();
            return drugList.length > 0 &&  drugList.map((item, index) => {
                const expiryDate = new Date(item.expiryDate);
                const isExpired = currentDate > expiryDate;
                if (isExpired){
                    return (
                        <tr key={index}>
                            <td>{index +1}</td>
                            <td>{item.drugName}</td>
                            <td>{item.categoryName}</td>
                            <td>{item.manufacturer}</td>
                            <td>{item.drugPrice}</td>
                            <td>{item.drugQuantity}</td>
                            <td>{formatDateAndTime(item.expiryDate, "date")}</td>
                            <td>{getExpiryDate(formatDate(item.expiryDate))}</td>
                        </tr>
                    );
                }
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable8 = () => {
        try {
            return drugList.length > 0 &&  drugList.map((item, index) => {
                const stockTotalPrice = parseFloat(item.drugStockPrice) * parseInt(item.drugQuantity);
                const sellsTotalPrice = parseFloat(item.drugPrice) * parseInt(item.drugQuantity);
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.drugName}</td>
                        <td>{item.categoryName}</td>
                        <td>{item.manufacturer}</td>
                        <td>{item.drugQuantity}</td>
                        <td>{item.drugStockPrice}</td>
                        <td>{moneyFormat(parseFloat(stockTotalPrice))}</td>
                        <td>{moneyFormat(parseFloat(item.drugPrice))}</td>
                        <td>{moneyFormat(parseFloat(sellsTotalPrice))}</td>
                        <td>{moneyFormat(parseFloat(sellsTotalPrice) - parseInt(stockTotalPrice))}</td>
                        <td>{item.updatedBy}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }
    const onEdit2 = (e) => {
        setFormData2({
            ...formData2,
            [e.target.id]: e.target.value,
        });
    }
    const onEdit4 = (e) => {
        setFormData4({
            ...formData4,
            [e.target.id]: e.target.value,
        });
    }
    const onEdit5 = (e) => {
        setFormData5({
            ...formData5,
            [e.target.id]: e.target.value,
        });
    }
    const onEdit6 = (e) => {
        setFormData6({
            ...formData6,
            [e.target.id]: e.target.value,
        });
    }


    const onSubmit = async () => {
        if (formData.categoryName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter drug category name", "error");
            return false;
        }

        if (formData.drugCategoryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/pharmacy/drug-category/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Category Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/pharmacy/drug-category/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Category Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit2 = async () => {
        if (formData2.subCategoryName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub category menu", "error");
            return false;
        }

        if (formData2.drugCategoryID.trim() === "") {
            showAlert("EMPTY FIELD", "Please select drug sub category", "error");
            return false;
        }


        if (formData2.drugSubcategoryID === "") {
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}staff/pharmacy/drug-sub-category/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Sub Category Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal").click();
                        resetFormData2();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}staff/pharmacy/drug-sub-category/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Sub Category Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal").click();
                        resetFormData2();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit4 = async () => {
        if (formData4.subSubCategoryName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub sub category menu", "error");
            return false;
        }

        if (formData4.drugSubSubcategoryID === "") {
            let sendData = {
                ...formData4,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}staff/pharmacy/drug-sub-sub-category/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Sub Sub Category Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal4").click();
                        resetFormData4();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData4,
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}staff/pharmacy/drug-sub-sub-category/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Sub Sub Category Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal4").click();
                        resetFormData4();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit5 = async () => {
        if (formData5.manufacturerName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub category menu", "error");
            return false;
        }
        console.log(formData5)

        if (formData5.manufacturerID === "") {
            let sendData = {
                ...formData5,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}staff/pharmacy/manufacturer/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Manufacturer Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal5").click();
                        resetFormData5();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData5,
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}staff/pharmacy/manufacturer/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Manufacturer Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal5").click();
                        resetFormData5();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit6 = async () => {
        if (formData6.drugName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter drug name", "error");
            return false;
        }

        if (formData6.drugCategoryID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select drug category", "error");
            return false;
        }
        if (formData6.drugStockPrice.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter drug price", "error");
            return false;
        }
        if (formData6.drugPrice.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter drug sales price", "error");
            return false;
        }
        if (formData6.drugQuantity.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter drug quantity", "error");
            return false;
        }

        if (formData6.drugStatus.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select drug status", "error");
            return false;
        }

        if (formData6.drugID === "") {
            let sendData = {
                ...formData6,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}staff/pharmacy/drug/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal6").click();
                        resetFormData6();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData6,
                manufactureDate: formatDate(formData6.manufactureDate),
                expiryDate: formatDate(formData6.expiryDate),
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}staff/pharmacy/drug/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Drug Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal6").click();
                        resetFormData6();
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Drug"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <ul className="nav nav-tabs customtab" role="tablist">
                                        <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#drug" role="tab"><span className="hidden-sm-up"><i className="fa fa-pills text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Drug</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link " data-toggle="tab" href="#home2" role="tab"><span className="hidden-sm-up"><i className="fa fa-cube text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Drug Category</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#profile2" role="tab"><span className="hidden-sm-up"><i className="fa fa-cubes text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Drug Sub Category</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#sub-sub" role="tab"><span className="hidden-sm-up"><i className="fa fa-sitemap text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Drug Sub Sub Category</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#manufacturer" role="tab"><span className="hidden-sm-up"><i className="fa fa-building text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Manufacturer</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#expired-drugs" role="tab"><span className="hidden-sm-up"><i className="fa fa-tablets text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Expired Drugs</span></a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#drug-inventory" role="tab"><span className="hidden-sm-up"><i className="fa fa-warehouse text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Drug Inventory</span></a> </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="drug" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Drug</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal" data-target="#drug-model" onClick={()=> resetFormData6()}>
                                                            <i className="fa fa-plus"></i> Add Drug
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable tableID="drug-table" header={header6} body={showTable6()} title="Drug List" />
                                        </div>
                                        <div className="tab-pane  p-10" id="home2" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Drug Category</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal" data-target="#category-modal" onClick={()=> resetFormData()}>
                                                            <i className="fa fa-plus"></i> Add Drug Category
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable tableID="category-tbl" header={header} body={showTable()} title="DRUG Category List" />
                                        </div>
                                        <div className="tab-pane  p-10" id="profile2" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Drug Sub Category</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal"
                                                                data-target="#sub-category" onClick={() => resetFormData2()}>
                                                            <i className="fa fa-plus"></i> Add Drug Sub Category
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable  tableID="sub-category-tbl"  header={header3} body={showTable3()} title="Drug Sub Category List" />
                                        </div>
                                        <div className="tab-pane  p-10" id="sub-sub" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Drug Sub Category</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal"
                                                                data-target="#sub-sub-category" onClick={() => resetFormData4()}>
                                                            <i className="fa fa-plus"></i> Add Drug Sub Sub Category
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable  tableID="sub-sub-cat"  header={header4} body={showTable4()} title="Drug Sub Sub Category List" />
                                        </div>
                                        <div className="tab-pane  p-10" id="manufacturer" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Manufacturer</h4>
                                                <div className="ml-auto">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-info" data-toggle="modal"
                                                                data-target="#manufacturer-modal" onClick={() => resetFormData5()}>
                                                            <i className="fa fa-plus"></i> Add Drug Manufacturer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable  tableID="manufacturer-model"  header={header5} body={showTable5()} title="Drug Manufacturer List" />
                                        </div>
                                        <div className="tab-pane  p-10" id="expired-drugs" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Expired Drugs</h4>
                                                <div className="ml-auto">

                                                </div>
                                            </div>
                                            <DataTable  tableID="expired-drug"  header={header7} body={showTable7()} title="Expired Drugs List" />
                                        </div>
                                        <div className="tab-pane  p-10" id="drug-inventory" role="tabpanel">
                                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                                <h4 className="card-title">Drug Inventory</h4>
                                                <div className="ml-auto">

                                                </div>
                                            </div>
                                            <DataTable  tableID="inventory"  header={header8} body={showTable8()} title="Drug Inventory List" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DrugCategoryForm
                    title="Manage Drug Category"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                    id="category-modal"
                />
                <DrugSubCategoryForm
                    id="sub-category"
                    close="closeModal"
                    title="Manage Drug Sub Category"
                    formData={formData2}
                    drugCategoryList={drugCategoryList}
                    onEdit={onEdit2}
                    onSubmit={onSubmit2}
                    IsFormLoading={IsFormLoading2}
                    setFormData={setFormData2}
                />
                <DrugSubSubCategoryForm
                    id="sub-sub-category"
                    close="closeModal4"
                    title="Manage Drug Sub Category"
                    formData={formData4}
                    drugCategoryList={drugCategoryList}
                    drugSubCategoryList={drugSubCategoryList}
                    onEdit={onEdit4}
                    onSubmit={onSubmit4}
                    IsFormLoading={IsFormLoading2}
                    setFormData={setFormData4}
                />
                <ManufacturerForm
                    id="manufacturer-modal"
                    close="closeModal5"
                    title="Manufacturer"
                    formData={formData5}
                    onEdit={onEdit5}
                    onSubmit={onSubmit5}
                    IsFormLoading={IsFormLoading2}
                    setFormData={setFormData5}
                />
                <DrugForm
                    id="drug-model"
                    close="closeModal6"
                    title="Manage Drug"
                    formData={formData6}
                    drugCategoryList={drugCategoryList}
                    drugSubCategoryList={drugSubCategoryList}
                    drugSubSubCategoryList={drugSubSubCategoryList}
                    manufacturerList={manufacturerList}
                    onEdit={onEdit6}
                    onSubmit={onSubmit6}
                    IsFormLoading={IsFormLoading2}
                    setFormData={setFormData6}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(Drugs);

