import React from "react";
import { useState } from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from 'axios'
import {currencyConverter, formatDate, formatDateAndTime, roleNames, serverLink} from "../url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "../common/loader";
import Chart from "react-google-charts";
import StatusChart from "react-google-charts";
import ApexChart from "react-apexcharts";
import {FaHospitalUser, FaRegUser, FaUserNurse} from "react-icons/fa";
import {TbCalendarUser} from "react-icons/tb";
import {FaUserDoctor} from "react-icons/fa6";
import {MdOutlineVerifiedUser} from "react-icons/md";
import StateData from "../../components/resources/state_and_lga.json";
import NotificationComponent from "../common/notification/notification-system";

const DashBoard = (props) => {
    const token = props.loginData[0]?.token;
    const [loading, setLoading] = useState(true);
    const [totalPatients, setTotalPatients] = useState(0);
    const [totalOutpatients, setTotalOutpatients] = useState(0);
    const [totalInpatients, setTotalInpatients] = useState(0);
    const [totalDoctors, setTotalDoctors] = useState(0);
    const [totalNurses, setTotalNurses] = useState(0);
    const [totalAdmin, setTotalAdmin] = useState(0);
    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);
    const [stateDistribution, setStateDistribution] = useState([]);
    const [patientGenderType, setPatientGenderType] = useState([])
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [admissionStatus, setAdmissionStatus] = useState([])
    const [imagingStatus, setImagingStatus] = useState([])
    const [labStatus, setLabStatus] = useState([])
    const [staffRoles, setStaffRoles] = useState([])
    const [appointmentByMonth, setAppointmentByMonth] = useState([])


    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true)
        let record = {};
        await axios.get(`${serverLink}staff/report/dashboard/data`, token)
            .then(async (result) => {
                if (result.data.message === 'success'){
                    const patient = result.data.patient;
                    const user = result.data.user;
                    const doctor_specialisation = result.data.doctor_specialisation;
                    const specialisation = result.data.specialisation;
                    const appointment = result.data.appointment;
                    const admission = result.data.admission;
                    const prescription = result.data.prescription;
                    const imaging = result.data.imaging;
                    const lab = result.data.lab;
                    const service = result.data.service;
                    const distinctAppointmentDate = [ ...new Set(appointment.map((item) => `${formatDateAndTime(item.appointmentDate, 'short_month')}-${formatDateAndTime(item.appointmentDate, 'year_only')}`))];
                    let gender = ['Male', 'Female'];
                    let appointment_status = ['Scheduled', 'Checked In', 'Vital Taken', 'Completed', 'Missed'];
                    let admission_status = ['Active', 'Discharged'];
                    let imaging_status = ['Pending', 'Result Entered', 'Cancelled'];
                    let lab_status = ['Requested', 'Specimen Taken', 'Result Confirmed', 'Result Entered', 'Result Rejected', 'Cancelled'];


                    //Set count card
                    setTotalPatients(patient.length)
                    setTotalOutpatients(appointment.filter(e=>e.appointmentStatus !== 'Completed').length)
                    setTotalInpatients(admission.filter(e=>e.status === 'Active').length)
                    setTotalNurses(user.filter(e=>e.role === 'Nurse').length)
                    setTotalDoctors(user.filter(e=>e.role === 'Doctor').length)
                    setTotalAdmin(user.filter(e=>e.role === 'Admin').length)


                    //Initialize Data
                    let patient_state_data = [];
                    let stateTypes = [];
                    let patientCount = [];
                    let patientGenderData = [];
                    let appointmentStatusData = [];
                    let admissionStatusData = [];
                    let imagingStatusData = [];
                    let labStatusData = [];
                    let staffRolesData = [];
                    let monthlyAppointmentData = [];

                    StateData.map((e, i) => {
                        let count = patient.filter(item => item.state === e.state).length
                        patientCount.push(count)
                        stateTypes.push(e.state)
                        patient_state_data.push([e.state, count]);
                    })

                    //patient trend by gender
                    gender.map((e, i) => {
                        let count = patient.filter(item => item.gender === e).length
                        patientGenderData.push([e, count]);
                    })

                    //Appointment trend by status
                    appointment_status.map((e, i) => {
                        let count = appointment.filter(item => item.appointmentStatus === e).length
                        appointmentStatusData.push([e, count]);
                    })

                    //Admission trend by status
                    admission_status.map((e, i) => {
                        let count = admission.filter(item => item.status === e).length
                        admissionStatusData.push([e, count]);
                    })

                    //Imaging trend by status
                    imaging_status.map((e, i) => {
                        let count = imaging.filter(item => item.status === e).length
                        imagingStatusData.push([e, count]);
                    })

                    //Lab trend by status
                    lab_status.map((e, i) => {
                        let count = lab.filter(item => item.testStatus === e).length
                        labStatusData.push([e, count]);
                    })

                    //Staff trend by role
                    roleNames.map((e, i) => {
                        let count = user.filter(item => item.role === e).length
                        staffRolesData.push([e, count]);
                    })

                    //Appointment trend by month
                    distinctAppointmentDate.map((items, int) => {
                        let status_count = [];
                        appointment_status.map((e, i) => {
                            let count = appointment.filter(item => `${formatDateAndTime(item.appointmentDate, 'short_month')}-${formatDateAndTime(item.appointmentDate, 'year_only')}` === items &&  item.appointmentStatus === e).length
                            status_count.push(count)
                        })
                        monthlyAppointmentData.push([items, ...status_count]);
                    })


                    //Set Chart Data
                    setApexChartOption({
                        ...apexChartOption,
                        chart: {
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: stateTypes
                        }
                    })
                    setApexSeries([{
                        name: "Count",
                        data: patientCount
                    }])

                    setStateDistribution(patient_state_data)
                    setPatientGenderType(patientGenderData)
                    setAppointmentStatus(appointmentStatusData)
                    setAdmissionStatus(admissionStatusData)
                    setImagingStatus(imagingStatusData)
                    setLabStatus(labStatusData)
                    setStaffRoles(staffRolesData)
                    setAppointmentByMonth(monthlyAppointmentData)


                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    const patientGenderData = [["Gender", "Count"], ...patientGenderType];
    const patientGenderOptions = {
        title: "Patient Trend By Gender",
        pieHole: 0.4,
        is3D: true,
    };

    const appointmentAnalyticsData = [["Status", "Count"], ...appointmentStatus];
    const appointmentAnalyticsOptions = {
        title: "Appointment Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const admissionAnalyticsData = [["Status", "Count"], ...admissionStatus];
    const admissionAnalyticsOptions = {
        title: "Admission Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const imagingAnalyticsData = [["Status", "Count"], ...imagingStatus];
    const imagingAnalyticsOptions = {
        title: "Imaging Request By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const labAnalyticsData = [["Status", "Count"], ...labStatus];
    const labAnalyticsOptions = {
        title: "Lab Request By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const roleAnalyticsData = [["Count", "Role"], ...staffRoles];
    const roleAnalyticsOptions = {
        title: "Staff Trend By Roles",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
        height: '100%',
    };

    const appointmentMonthData = [['Month and Year', 'Scheduled', 'Checked In', 'Vital Taken', 'Completed', 'Missed'], ...appointmentByMonth];
    const appointmentMonthOptions = {
        title: "Appointments Status by Months",
        chartArea: { width: "80%", height: "100%" },
        isStacked: true,
        hAxis: {
            title: "Appointments",
            minValue: 0,
        },
        vAxis: {
            title: "Months",
        },
    };

    return (loading ? <PageLoader/> :
            <>
                <PageTitle title={["Home", "Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md-2 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalPatients}</h2>
                                            <h6 className="text-cyan"> Patients</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><FaRegUser size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-2 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalOutpatients}</h2>
                                            <h6 className="text-cyan"> Outpatients</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><TbCalendarUser size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-2 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalInpatients}</h2>
                                            <h6 className="text-cyan"> Inpatients</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><FaHospitalUser size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-2 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalNurses}</h2>
                                            <h6 className="text-cyan"> Nurses</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><FaUserNurse size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-2 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalDoctors}</h2>
                                            <h6 className="text-cyan"> Doctors</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><FaUserDoctor size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-2 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalAdmin}</h2>
                                            <h6 className="text-cyan"> Admin Staff</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><MdOutlineVerifiedUser size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Patient Registration Trend By State</h4>
                                            {

                                                <ApexChart
                                                    options={apexChartOption}
                                                    series={apexSeries}
                                                    type="bar"
                                                    height={250}
                                                    width="100%"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        patientGenderType.length > 0 &&
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="310px"
                                            data={patientGenderData}
                                            options={patientGenderOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        appointmentStatus.length > 0 &&
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="310px"
                                            data={appointmentAnalyticsData}
                                            options={appointmentAnalyticsOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        admissionStatus.length > 0 &&
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="310px"
                                            data={admissionAnalyticsData}
                                            options={admissionAnalyticsOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        imagingStatus.length > 0 &&
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="310px"
                                            data={imagingAnalyticsData}
                                            options={imagingAnalyticsOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        labStatus.length > 0 &&
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="310px"
                                            data={labAnalyticsData}
                                            options={labAnalyticsOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        staffRoles.length > 0 &&
                                        <Chart
                                            chartType="LineChart"
                                            data={roleAnalyticsData}
                                            options={roleAnalyticsOptions}
                                            width="100%"
                                            height="400px"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card bg-white" style={{borderRadius: '10px'}}>
                                <div className="card-header bg-white" style={{borderRadius: '10px'}}>
                                    <div className="card-title"><h4><b>Appointments Status by Month and Year</b></h4></div>
                                </div>
                                <div className="card-body">
                                    {
                                        appointmentByMonth.length > 0 &&
                                        <Chart
                                            chartType="BarChart"
                                            data={appointmentMonthData}
                                            options={appointmentMonthOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        {/*<NotificationComponent/>*/}
                    </div>
                </div>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(DashBoard);

