import {formatAMPM, formatDate, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import Chart from "react-google-charts";
import BPChart from "react-google-charts";
import HightChart from "react-google-charts";
import WeightChart from "react-google-charts";
import {TbCalendarUser} from "react-icons/tb";
import {BsHeartPulse} from "react-icons/bs";


const PatientVitalsChart = ({patientSerial, handleRemount, serviceID, serviceName, loginData, setVitalFormData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [vitalList, setVitalList] = useState([])
    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);
    const [state, setState] = useState({
        graph_label: [],
        temp_val: [],
        height_val: [],
        weight_val: [],
        bp_systolic_val: [],
        bp_diastolic_val: [],
        height_unit: '',
        weight_unit: '',
        height: 0,
        weight: 0,
        temp: 0,
        bp: 0,
    });
    const header = ["S/N", "Blood Pressure", "Height", "Weight", "Temperature", "Respiration", "Pulse", "BMI", "BSA", "SPO2", "Remark", "Taken On", "Time Taken", "Taken By", "Edit"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/vitals/list/${serviceName}/${serviceID}`, token)
            .then((result) => {
                if (result.data.length > 0) {

                    let graph_label = [];
                    let temp_val = [];
                    let weight_val = [];
                    let height_val = [];
                    let bp_systolic_val = [];
                    let bp_diastolic_val = [];
                    let _height, _weight, temp, _bp= 0;
                    let height_unit, weight_unit = '';
                    let null_val = 0;

                    result.data.map((v, index) => {
                        if (index < 5) {
                            let timeStamp = formatDateAndTime(v.submittedOn, 'date_and_time');
                            graph_label.push(formatDateAndTime(v.submittedOn, 'date'))

                            //TEMPERATURE GRAPH GENERATOR
                            if (v.temperature !== '') {
                                if (v.temperature_unit !== 'C') {
                                    let fToCel = (parseFloat(v.temperature) - 32) * 5 / 9;
                                    temp_val.push([timeStamp, parseFloat(fToCel.toFixed(1))]);
                                    index === 0 ?  temp = v.temperature : null_val = 1;
                                } else {
                                    temp_val.push([timeStamp, parseFloat(v.temperature)]);
                                    index === 0 ?  temp = v.temperature : null_val = 1;
                                }
                            } else {
                                temp_val.push([timeStamp, 0]);
                            }

                            //HEIGHT GRAPH GENERATOR
                            if (v.height !== '') {
                                let height = parseFloat(v.height)
                                if (v.height_unit !== 'M') {
                                    let CMTom = height * 0.01;
                                    height_val.push([timeStamp, parseFloat(CMTom.toFixed(2))]);
                                    index === 0 ?  _height = v.height : null_val = 1;
                                    index === 0 ?  height_unit = v.height_unit : null_val = 1;
                                } else {
                                    height_val.push([timeStamp, parseFloat(height.toFixed(2))]);
                                    index === 0 ?  _height = v.height : null_val = 1;
                                    index === 0 ?  height_unit = v.height_unit : null_val = 1;
                                }
                            } else {
                                height_val.push([timeStamp, 0]);
                            }

                            //WEIGHT GRAPH GENERATOR
                            if (v.weight !== '') {
                                if (v.weight_unit !== 'KG') {
                                    let LBToKG = parseFloat(v.weight) * 0.453592;
                                    weight_val.push([timeStamp, parseFloat(LBToKG.toFixed(1))]);
                                    index === 0 ?  _weight = v.weight : null_val = 1;
                                    index === 0 ?  weight_unit = v.weight_unit : null_val = 1;
                                } else {
                                    weight_val.push([timeStamp, parseFloat(v.weight)]);
                                    index === 0 ?  _weight = v.weight : null_val = 1;
                                    index === 0 ?  weight_unit = v.weight_unit : null_val = 1;
                                }
                            } else {
                                weight_val.push([timeStamp, 0]);
                            }

                            //BLOOD PRESSURE GRAPH GENERATOR
                            if (v.bloodPressure !== '' || v.bloodPressure !== null) {
                                const bp = v.bloodPressure.split('/');
                                bp_systolic_val.push([timeStamp, parseInt(bp[0]), parseInt(bp[1])]);
                                index === 0 ?  _bp = v.bloodPressure : null_val = 1;
                            } else {
                                bp_systolic_val.push([timeStamp, 0, 0]);
                            }

                        }
                    })

                    setState(prevState => ({
                        graph_label: graph_label,
                        temp_val: temp_val,
                        height_val: height_val,
                        weight_val: weight_val,
                        bp_systolic_val: bp_systolic_val,
                        bp_diastolic_val: bp_diastolic_val,
                        height_unit: height_unit,
                        weight_unit: weight_unit,
                        height: _height,
                        weight: _weight,
                        temp: temp,
                        bp: _bp,
                    }));

                    setVitalList(result.data);
                }else {
                    setVitalList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const tempAnalyticsData = [["Count", "Date"], ...state.temp_val];
    const tempAnalyticsOptions = {
        title: "Temperature Trend",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
        height: '100%',
    };
    const HeightAnalyticsData = [["Count", "Date"], ...state.height_val];
    const HeightAnalyticsOptions = {
        title: "Height Trend",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
        height: '100%',
    };
    const WeightAnalyticsData = [["Count", "Date"], ...state.weight_val];
    const WeightAnalyticsOptions = {
        title: "Weight Trend",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
        height: '100%',
    };

    const BPdata = [
        ["Date Captured", "Systolic", "diastolic"],
            ...state.bp_systolic_val,
    ];

     const BPoptions = {
        title: "Blood Pressure",
        curveType: "function",
        legend: { position: "bottom" },
    };


    const  showTable = () => {
        try {
            return vitalList.length > 0 &&  vitalList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.bloodPressure}{item.bloodPressure_unit}</td>
                        <td>{item.height}{item.height_unit} </td>
                        <td>{item.weight}{item.weight_unit} </td>
                        <td>{item.temperature}{item.temperature_unit} </td>
                        <td>{item.respiration}</td>
                        <td>{item.pulse}</td>
                        <td>{item.bmi}{item.bmi_unit} </td>
                        <td>{item.bsa}</td>
                        <td>{item.spo2}%</td>
                        <td>{item.remark}</td>
                        <td>{formatDateAndTime(item.dateTaken, 'date')}</td>
                        <td>{formatAMPM(item.timeTaken)}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            {loginData[0]?.userID !== item.submittedBy ? <>--</> :
                                <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal"
                                   data-target="#vital-modal"
                                   onClick={() => {
                                       setVitalFormData({
                                           vitalID: item.vitalID,
                                           appointmentID: item.appointmentID,
                                           pulse: item.pulse,
                                           respiration: item.respiration,
                                           bloodPressure: item.bloodPressure,
                                           bloodPressure_unit: item.bloodPressure_unit,
                                           temperature: item.temperature,
                                           temperature_unit: item.temperature_unit,
                                           weight: item.weight,
                                           weight_unit: item.weight_unit,
                                           height: item.height,
                                           height_unit: item.height_unit,
                                           bmi: item.bmi,
                                           bmi_unit: item.bmi_unit,
                                           bsa: item.bsa,
                                           spo2: item.spo2,
                                           dateTaken: formatDate(item.dateTaken),
                                           timeTaken: item.timeTaken,
                                           remark: item.remark,
                                       })
                                   }}>
                                    Edit
                                </a>
                            }
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="container-fluid">
                <div className="row" style={{marginTop: '-20px'}}>
                    <div className=" col-md-3 col-6">
                        <div className="card border-bottom border-cyan text-white"
                             style={{backgroundColor: '#1f3445', borderRadius: '20px'}}>
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2>{state.bp}</h2>
                                        <h6 className="text-cyan"> Blood Pressure (BP)</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="text-cyan display-6"><span
                                            className="fa fa-heartbeat"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-3 col-6">
                        <div className="card border-bottom border-cyan text-white"
                             style={{backgroundColor: '#1f3445', borderRadius: '20px'}}>
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2>{state.temp}</h2>
                                        <h6 className="text-cyan"> Temperature</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="text-cyan display-6"><span className="fa fa-thermometer-half"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-3 col-6">
                        <div className="card border-bottom border-cyan text-white"
                             style={{backgroundColor: '#1f3445', borderRadius: '20px'}}>
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2>{state.height} <span className="text-cyan display-10 text-uppercase">{state.height_unit}</span></h2>
                                        <h6 className="text-cyan"> Height</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="text-cyan display-6"><span
                                            className="fa fa-balance-scale"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-3 col-6">
                        <div className="card border-bottom border-cyan text-white"
                             style={{backgroundColor: '#1f3445', borderRadius: '20px'}}>
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2>{state.weight} <span
                                            className="text-cyan display-10 text-uppercase">{state.weight_unit}</span>
                                        </h2>
                                        <h6 className="text-cyan"> Weight </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="text-cyan display-6"><span
                                            className="fa fa-weight"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    <BPChart
                                        chartType="LineChart"
                                        width="100%"
                                        height="400px"
                                        data={BPdata}
                                        options={BPoptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    <Chart
                                        chartType="LineChart"
                                        data={tempAnalyticsData}
                                        options={tempAnalyticsOptions}
                                        width="100%"
                                        height="300px"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    <HightChart
                                        chartType="LineChart"
                                        data={HeightAnalyticsData}
                                        options={HeightAnalyticsOptions}
                                        width="100%"
                                        height="300px"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    <WeightChart
                                        chartType="LineChart"
                                        data={WeightAnalyticsData}
                                        options={WeightAnalyticsOptions}
                                        width="100%"
                                        height="300px"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientVitalsChart);

