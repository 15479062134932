import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {currencyConverter, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {Link} from "react-router-dom";

function LabTest(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [laboratoryList, setLaboratoryList] = useState([])
    const header = ["S/N", "Test Name", "Laboratory Name", "Specimen", "Amount", "Delete"];

    useEffect( () => {
        getData();
    }, []);


    const getData = async () => {
        await axios.get(`${serverLink}laboratory/test/list/all`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setLaboratoryList(result.data.data);
                }else {
                    setLaboratoryList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}laboratory/test/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return laboratoryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.testName}</td>
                        <td>{item.laboratoryName}</td>
                        <td>{item.specimenName}</td>
                        <td>{currencyConverter(item.testAmount)}</td>
                        <td>
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={()=>showConfirm("Warning", `Are you sure you want to delete this lab test?`, "warning")
                                   .then( async (confirm) => {
                                       if (confirm) {
                                           delete_item(item.labTestID)
                                       }
                                   })}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Lab Test"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Lab Test</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <Link to="/lab/test-page" className="btn btn-info">
                                                    <i className="fa fa-plus"></i> Add Lab Test
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="Lab-Test" header={header} body={showTable()} title="Lab Test Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(LabTest);

