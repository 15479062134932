import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {currencyConverter, formatAMPM, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {Link} from "react-router-dom";
import { setGeneralDetails } from "../../../action/actions";
import {toast} from "react-toastify";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {projectEmail, projectName, projectPatientURL} from "../../resources/constants";

function LabRequestHistory(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);

    const header = [
        "S/N",
        "Patient Name",
        "Patient ID",
        "Test Name",
        "Amount",
        "Test Status",
        "Requested By",
        "Date Requested",
        "Action"
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        await axios
            .get(`${serverLink}laboratory/test/history`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientName}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.testName}</td>
                        <td>{currencyConverter(item.testAmount)}</td>
                        <td>{item.testStatus}</td>
                        <td>{item.doctorName}</td>
                        <td>{formatDateAndTime(item.submittedOn, 'date_and_time')}</td>
                        <td className="d-flex">
                            <Link to="/lab/test/dashboard"
                                  className={"btn btn-info btn-sm  mr-3"}
                                  onClick={()=>{
                                      props.setOnGeneralDetails(item);
                                  }}
                            >
                                Dashboard
                            </Link>
                            <a href="#"
                               className={"btn btn-danger btn-sm"}
                               onClick={()=>{
                                   showConfirm("Warning", `Are you sure you want to cancel this lab test request?`, "warning")
                                       .then( async (confirm) => {
                                           if (confirm) {
                                               cancelRequest(item)
                                           }
                                       })
                               }}
                            >
                                Cancel Request
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const cancelRequest = async (item) => {
        toast.info("please wait...");
        const sendData = {
            labTestRequestID: item.labTestRequestID,
            patientName: item.patientName,
            patientEmail: item.patientEmail,
            doctorName: item.doctorName,
            testName: item.testName,
            hospitalName: projectName,
            hospitalPatientURL: projectPatientURL,
            hospitalEmail: projectEmail,
            testStatus: 'Cancelled',
            submittedBy: props.loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}laboratory/test/cancel`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Lab test request has been cancelled");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Lab Test Request History"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Lab Test Request History List</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                    </div>
                                </div>
                                <DataTable
                                    tableID="labList"
                                    header={header}
                                    body={showTable()}
                                    title="Lab Test Request History Report"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LabRequestHistory);
