import React from "react";
import {
  setDashboardDetails,
  setLoginDetails,
  setPermissionDetails,
} from "../../action/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { decryptData, encryptData, serverLink } from "../url";
import logo from "../../images/enyimba-black.png";
import { useEffect } from "react";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    Username: "",
    Password: "",
  });
  useEffect(() => {
    props.setOnLoginDetails([]);
  }, []);

  const Login = (e) => {
    e.preventDefault();
    setLoading(true);
    toast.info("Please wait while login you in...");

    const data = {
      username: formData.Username,
      password: encryptData(formData.Password),
    };
    axios
      .post(`${serverLink}login/staff`, data)
      .then((res) => {
        if (res.data.message === "success") {
          toast.success("Login successful");
          props.setOnPermissionDetails(res.data.permissionData);
          props.setOnLoginDetails(res.data.userData);
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);
        } else {
          setLoading(false);
          toast.error("Wrong  credentials, please try again.");
        }
      })
      .catch((e) => {
        toast.error("Network Error!, please try again.");
      });
  };

  const getDashboardData = async () => {
    await axios
      .get(`${serverLink}shared/contents`)
      .then((result) => {
        props.setOnDashboardData(result.data);
      })
      .catch((e) => {
        console.log("Error Message Found", e);
      });
  };

  useEffect(() => {
    //    getDashboardData().then((r) => {});
  }, []);

  const onEdit = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <div
      className="auth-wrapper d-flex no-block justify-content-center align-items-center"
      style={{
        background: `url(../../assets/images/big/auth-bg.jpg) no-repeat center center`,
      }}
    >
      <div className="auth-box">
        <div id="loginform">
          <div className="d-flex justify-content-center align-items-center">
            <span className="db">
              <img src={logo} width={210} alt="logo" />
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <form
                className="form-horizontal m-t-20"
                id="loginform"
                onSubmit={Login}
              >
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="ti-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Username"
                    id="Username"
                    onChange={onEdit}
                    required
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon2">
                      <i className="ti-pencil"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    id="Password"
                    onChange={onEdit}
                    required
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="form-group row">
                  <div className="col-md-12">
                    <div className="custom-control custom-checkbox">
                      <Link
                        to="/forgot-password"
                        id="to-recover"
                        className="text-dark float-right"
                      >
                        <i className="fa fa-lock m-r-5"></i> Forgot password?
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <div className="col-xs-12 p-b-20">
                    <button
                      className="btn btn-block btn-lg btn-info"
                      type="submit"
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="recoverform">
          <div className="logo">
            <span className="db">
              <img src="../../assets/images/logo-icon.png" alt="logo" />
            </span>
            <h5 className="font-medium m-b-20">Recover Password</h5>
            <span>Enter your Email and instructions will be sent to you!</span>
          </div>
          <div className="row m-t-20">
            <form className="col-12" action="index.html">
              <div className="form-group row">
                <div className="col-12">
                  <input
                    className="form-control form-control-lg"
                    type="email"
                    required=""
                    placeholder="Username"
                  />
                </div>
              </div>
              <div className="row m-t-20">
                <div className="col-12">
                  <button
                    className="btn btn-block btn-lg btn-danger"
                    type="submit"
                    name="action"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
    setOnPermissionDetails: (p) => {
      dispatch(setPermissionDetails(p));
    },
    setOnDashboardData: (p) => {
      dispatch(setDashboardDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
